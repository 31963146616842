import React, { useRef, useState } from "react";
// import ReactDOM from "react-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Editor from "@monaco-editor/react";
import Cookies from "js-cookie";
import {
  // Paper,
  Button,
  Typography,
  Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  // Grid,
  // AppBar,
  // Toolbar,
  FormControl,
  InputLabel,
  // Select,
  // MenuItem,
  // FormHelperText,
  Popover,
} from "@material-ui/core";
import cache from "memory-cache";
import { WEB_URL } from "../../../utils/constants";
import axios from "../../../utils/axiosConfig";
import Select from "react-select";
import Loader from "../../loader";
import onWindowReload from "../../../utils/reloadFunction";
import supportedFileExtensions from "./supportedFileExtensions";
import { v1_question_images_link } from "./v1-links";
import { translationMissingMessage } from "./translationConstants";

// import '../../script.js';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "0px 5px",
  },
}));

export default (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  // let token = Cookies.get('token');
  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    marksScored,
    setMarksScored,
    totalMarks,
    setTotalMarks,
    wrongAnswerCount,
    setWrongAnswerCount,
    disable,
    setDisable,
    setTestData,
    windowSwitchCount,
    setWindowSwitch,
    codeLanguages,
  } = props;

  // const [isEditorReady, setIsEditorReady] = useState(false);
  // const valueGetter = useRef(testData[questionNo - 1].descriptiveText);
  const [answer, setAnswer] = React.useState("");
  const [error, setError] = React.useState("");
  const [language, setLanguage] = React.useState(examDetail[0].codinglang && examDetail[0].codinglang.toLocaleLowerCase());
  const [outputRes, setOutputRes] = React.useState("");
  const [isloading, setIsLoading] = useState();
  const [offCompile, setoffCompile] = useState(false);
  const [count, setCount] = React.useState(Number(0));
  const [col, setCol] = React.useState(0);
  const [tablesName, setTablesName] = React.useState("");
  const [totalCol, setTotalCol] = React.useState(0);
  const [programBoilerPlate, setProgramBoilerPlate] = React.useState(testData[questionNo - 1].programBoilerPlate);
  const [programBoilerPlateLoading, setProgramBoilerPlateLoading] = React.useState(false);
  const [numOfLines, setNumberOfLines] = React.useState(
    testData[questionNo - 1].programBoilerPlate ? testData[questionNo - 1].programBoilerPlate.split(/\r\n|\r|\n/).length : 0
  );
  const handleChange = async (event) => {
    setProgramBoilerPlateLoading(true)
    setError("");
    setOutputRes("");
    setLanguage(event.codinglanguage);
    // setAnswer('');
    setError("");
    setOutputRes("");
    setCount(0);
    setCol(0);
    getTables();
    // setProgramBoilerPlate('')
    // testData[questionNo -1].descriptiveText = ''
    testData[questionNo - 1].codinglang = event.codinglanguage;
    // console.log('testData[questionNo - 1]',testData[questionNo - 1])
    await axios
      .post("/test/programSkeleton", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        codingLang: event.codinglanguage,
        classname: testData[questionNo - 1].classname,
        methodname: testData[questionNo - 1].methodname,
        paramtype: testData[questionNo - 1].paramtype,
        paramvalue: testData[questionNo - 1].paramvalue,
        methodreturntype: testData[questionNo - 1].methodreturntype,
      })
      .then((response) => {
        console.log("HERE", response.data.programBoilerPlate);
        // setProgramBoilerPlate(response.data.programBoilerPlate)
        setAnswer(response.data.programBoilerPlate);
        testData[questionNo - 1].descriptiveText = response.data.programBoilerPlate;
        testData[questionNo - 1].programBoilerPlate = response.data.programBoilerPlate;
        setProgramBoilerPlateLoading(false)
      })
      .catch((error) => {
        // setError(error)
        console.log("error here", error);
        setProgramBoilerPlateLoading(false)
      });
    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);
  };

  // console.log('qData',error);
  // console.log('programSkeleton',testData[questionNo -1].programBoilerPlate);
  // console.log('testData',testData[questionNo -1])
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const options = {
    minimap: {
      enabled: false,
    },
    "contextmenu" : false
  };

  const executeCode = async () => {
    setIsLoading(true);
    setoffCompile(true);
    setError("");
    setOutputRes("");
    setIsLoading(false);
    setCol(0);
    if (language && language.toLowerCase() === "html") {
      setOutputRes(answer);
      setIsLoading(false);
    } else {
      axios
        .post("/test/compilecode", {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          code: answer,
          lang: language,
          qid: testData[questionNo - 1].qid,
          classname: testData[questionNo - 1].classname,
          testCaseFlag: false,
          examId: examDetail[0].id,
          methodname: testData[questionNo - 1].methodname,
        })
        .then((response) => {
          if (language && (language.toLowerCase() === "mysql" || language.toLowerCase() === "sql" || language.toLowerCase() === "pgsql")) {
            let res = response.data;
            if (res.sqlMessage) {
              setError(res.sqlMessage);
              testData[questionNo - 1].error = res.sqlMessage;
              setoffCompile(false);
            } else {
              setOutputRes(res);
              setIsLoading(false);
              setoffCompile(false);
              setCol(Object.keys(res[0]));
              testData[questionNo - 1].col = Object.keys(res[0]);
              testData[questionNo - 1].outPutRes = res;
            }
          } else {
            let res = response.data;
            // let res = JSON.stringify(response.data);
            if (res.includes("error") || res.includes("Warning")) {
              setError(res);
              setIsLoading(false);
              setoffCompile(false);
              testData[questionNo - 1].error = res;
            } else {
              setOutputRes(res);
              setIsLoading(false);
              setoffCompile(false);
              testData[questionNo - 1].outPutRes = res;
            }
          }
        })
        .catch((error) => {
          // setError(error)
          console.log("error here", error);
          setIsLoading(false);
          setoffCompile(false);
        });
    }
    testData[questionNo - 1].countCompilation = Number(count + 1);
    setCount(Number(count + 1));
    testData[questionNo - 1].descriptiveText = answer;
    testData[questionNo - 1].numOfLines = Number(answer.split(/\r\n|\r|\n/).length);

    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);
  };

  // get table column
  const column = col;

  // get table heading data
  const ThData = () => {
    if (outputRes) {
      return column.map((data) => {
        return <th key={data}>{data}</th>;
      });
    }
  };
  // get table row data
  const tdData = () => {
    if (outputRes) {
      return outputRes.map((data, index) => {
        return (
          <tr key={index}>
            {column.map((v, index) => {
              return <td key={index}>{data[v]}</td>;
            })}
          </tr>
        );
      });
    }
  };

  onWindowReload(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData);

  const open = Boolean(anchorEl);

  const supportedLanguages = [
    { id: 4, name: "c" },
    { id: 7, name: "c++" },
    { id: 10, name: "css" },
    { id: 16, name: "html" },
    { id: 18, name: "java" },
    { id: 19, name: "javascript" },
    { id: 26, name: "mysql" },
    { id: 27, name: "objective-c" },
    { id: 30, name: "pgsql" },
    { id: 31, name: "php" },
    { id: 37, name: "python" },
    { id: 42, name: "ruby" },
    { id: 49, name: "sql" },
    { id: 54, name: "vb" },
    { id: 57, name: "c#" },
    { id: 58, name: "jquery" },
  ];

  function handleEditorChange(value, event) {
    if (language && (language.toLowerCase() === "mysql" || language.toLowerCase() === "sql" || language.toLowerCase() === "pgsql")) {
      testData[questionNo - 1].descriptiveText = value;
      testData[questionNo - 1].codinglang = language;
      testData[questionNo - 1].error = "";
      testData[questionNo - 1].outPutRes = "";
      setAnswer(value);
      setError("");
      setOutputRes("");
      setIsLoading(false);
      setCol(0);
    } else {
      testData[questionNo - 1].descriptiveText = value;
      testData[questionNo - 1].numOfLines = Number(answer.split(/\r\n|\r|\n/).length);
      testData[questionNo - 1].codinglang = language;
      testData[questionNo - 1].error = "";
      testData[questionNo - 1].outPutRes = "";
      setAnswer(value);
      setOutputRes("");
      setError("");
      setIsLoading(false);
      setNumberOfLines(Number(answer.split(/\r\n|\r|\n/).length));
    }

    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);
  }

  const editorRef = useRef(null);
  const [code,setCode]=useState("");
  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    editor.onKeyDown((event) => {
    console.log("event",event)
     const { keyCode, ctrlKey, metaKey } = event;
     if ((keyCode === 33 || keyCode === 52 || keyCode==93) && (metaKey || ctrlKey)){
       event.preventDefault();
       console.log("Don't copy paste your code.");
       
     }
   });
   }

  const getTables = () => {
    if (language && (language.toLowerCase() === "mysql" || language.toLowerCase() === "sql" || language.toLowerCase() === "pgsql")) {
      axios
        .get("/test/getTables", {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          let res = response.data;
          setTablesName(res);
          setTotalCol(Object.keys(res[0]));
        })
        .catch((error) => {
          console.log("error here", error);
        });
    }
  };

  // get table column
  const numOfCol = totalCol;
  // get table heading data
  const dataTableTH = () => {
    return numOfCol.map((data) => {
      return <th key={data}>Data Tables</th>;
    });
  };
  // get table row data
  const dataTableTD = () => {
    return tablesName.map((data, index) => {
      return (
        <tr key={index}>
          {numOfCol.map((v, index) => {
            return <td key={index}>{data[v]}</td>;
          })}
        </tr>
      );
    });
  };

  React.useEffect(() => {
    getTables();
    if (testData[questionNo - 1].codelang === "Python") {
      testData[questionNo - 1].programBoilerPlate = testData[questionNo - 1].programBoilerPlate.replace(/\|\^\^\|/g, ",");
      if (
        testData[questionNo - 1].descriptiveText !== "null" &&
        testData[questionNo - 1].descriptiveText !== null &&
        testData[questionNo - 1].descriptiveText !== ""
      ) {
        testData[questionNo - 1].descriptiveText = testData[questionNo - 1].descriptiveText.replace(/\|\^\^\|/g, ",");
      }
      // console.log(testData[questionNo - 1].error)
    }
    if (questionNo === testData.length) {
      setDisable(true);
    }
    setLanguage(
      testData[questionNo - 1].codinglang !== undefined
        ? testData[questionNo - 1].codinglang
        : examDetail[0].codinglang && examDetail[0].codinglang.toLocaleLowerCase()
    );
    setAnswer(
      testData[questionNo - 1].descriptiveText !== "null" &&
        testData[questionNo - 1].descriptiveText !== null &&
        testData[questionNo - 1].descriptiveText !== ""
        ? testData[questionNo - 1].descriptiveText
        : programBoilerPlate
    );
    setError(
      testData[questionNo - 1].error !== "null" &&
        testData[questionNo - 1].error !== null &&
        testData[questionNo - 1].error !== "" &&
        testData[questionNo - 1].error !== undefined
        ? testData[questionNo - 1].codelang === "Python"
          ? testData[questionNo - 1].error.includes("python: can't open file")
            ? ""
            : testData[questionNo - 1].error
          : testData[questionNo - 1].error
        : ""
    );
    setOutputRes(
      testData[questionNo - 1].outPutRes !== "null" && testData[questionNo - 1].outPutRes !== null && testData[questionNo - 1].outPutRes !== ""
        ? testData[questionNo - 1].outPutRes
        : ""
    );
    setCount(testData[questionNo - 1].countCompilation !== undefined ? Number(testData[questionNo - 1].countCompilation) : 0);
    setCol(testData[questionNo - 1].col !== undefined ? testData[questionNo - 1].col : 0);
    setNumberOfLines(testData[questionNo - 1].numOfLines !== undefined ? testData[questionNo - 1].numOfLines : 0);
    setProgramBoilerPlate(testData[questionNo - 1].programBoilerPlate);
    if (clearFlag) {
      testData[questionNo - 1].descriptiveText = "";
      testData[questionNo - 1].selectedChoiceId = "";
      testData[questionNo - 1].error = "";
      testData[questionNo - 1].outPutRes = "";
      testData[questionNo - 1].countCompilation = 0;
      testData[questionNo - 1].col = 0;
      testData[questionNo - 1].buttonStyle = "Not Answered";
      testData[questionNo - 1].numOfLines = 0;
      testData[questionNo - 1].programBoilerPlate = programBoilerPlate;
      let collectData = { quesData: testData, examDetail: examDetail };
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
      window.localStorage.setItem(examId, encode);

      setClearFlag(false);
    }
    // testData[questionNo -1].descriptiveText = answer;
    // testData[questionNo -1].codinglang = language;

    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);

    // console.log('testData',testData);
  }, [questionNo, clearFlag, language]);

  return (
    <>
      {/* <strong> Question No {questionNo}</strong>< br/>  */}
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="question-num">
          Q.{questionNo} of {testData.length}
        </span>
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        //  PaperProps={{
        //     style: { height: '4%' ,textAlign:"center"},
        //   }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h5>{testData[questionNo - 1].qid}</h5>
      </Popover>
      {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
      {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
      {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
      {(testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== undefined &&
        testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== null &&
        testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== "") ||
        (testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
          testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
          testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== "") ? (
        <div>
          {supportedFileExtensions.image.includes(
            testData[questionNo - 1].qresourcepath
              .split("|^^|")[0]
              .split(".")
            [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
          ) ? (
            <img
              src={
                testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                  ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                  : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
              }
              key={testData[questionNo - 1].qid}
              style={{ maxWidth: "100%" }}
              alt=""
            />
          ) : supportedFileExtensions.audio.includes(
            testData[questionNo - 1].qresourcepath
              .split("|^^|")[0]
              .split(".")
            [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
          ) ? (
            <audio
              controls
              src={
                testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                  ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                  : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
              }
              key={testData[questionNo - 1].qid}
              style={{ maxWidth: "100%" }}
              alt=""
            />
          ) : supportedFileExtensions.video.includes(
            testData[questionNo - 1].qresourcepath
              .split("|^^|")[0]
              .split(".")
            [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
          ) ? (
            <video
              controls
              src={
                testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                  ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                  : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
              }
              key={testData[questionNo - 1].qid}
              style={{ maxWidth: "100%" }}
              alt=""
            />
          ) : supportedFileExtensions.file.includes(
            testData[questionNo - 1].qresourcepath
              .split("|^^|")[0]
              .split(".")
            [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
          ) ? (
            <a
              style={{ color: "#337ab7", fontSize: "16px" }}
              href={
                testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                  ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                  : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
              }
              download
            >
              CLICK TO DOWNLOAD
            </a>
          ) : null}
          <br />
          <br />
        </div>
      ) : (
        <></>
      )}
      <br />
      <br />
      <hr></hr>
      <InputLabel id="select-coding-language">Language</InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={language && codeLanguages.find((i) => i.codinglanguage.toLowerCase() === language.toLowerCase())}
          onChange={(event) => handleChange(event)}
          options={
            examDetail[0].codinglang
              ? examDetail[0].codinglang.toLowerCase() === "all-p" || examDetail[0].codinglang.toLowerCase() === "all_p"
                ? codeLanguages.filter(i => !["9", "11"].includes(i.id))
                : codeLanguages
              : codeLanguages
          }
          placeholder="Select Language..."
          className="basic-single"
          classNamePrefix="select"
          name="color"
          getOptionLabel={(e) => e.codinglanguage}
          getOptionValue={(e) => e.id}
          isDisabled={
            examDetail[0].codinglang
              ? examDetail[0].codinglang.toLowerCase() === "all-p" || examDetail[0].codinglang.toLowerCase() === "all_p"
                ? false
                : true
              : false
          }
        />
      </FormControl>

      <div className="row">
        <div
          className={
            language && (language.toLowerCase() === "mysql" || language.toLowerCase() === "sql" || language.toLowerCase() === "pgsql")
              ? "col-md-9 col-sm-9 editor"
              : "col-md-12 editor"
          }
        >
          {programBoilerPlateLoading ?
            <span>Please Wait...</span>
            :
            <>
              <Editor
                height="70vh"
                language={language ? language.toLowerCase() : null}
                theme="vs-dark"
                options={options}
                value={testData[questionNo - 1].descriptiveText !== null ? answer : programBoilerPlate}
                onChange={handleEditorChange}
                handlePastedText={true}
                onMount={handleEditorDidMount} 
              />
              <br></br>
              <br></br>
            </>
          }
          <div>
            <Button variant="outlined" name="compilecode" id="compilecode" color="primary" onClick={() => executeCode()} disabled={offCompile}>
              Compile & Execute Code
            </Button>
          </div>{" "}
          <br></br>
        </div>
        {
        // language && language.toLowerCase() === "php" ? (
        //   <div className="col-md-6" style={{ height: "70vh", overflow: "scroll" }}>
        //     {!error ? (
        //       outputRes != "" || outputRes != undefined ? (
        //         <>
        //           {" "}
        //           <label>Output : </label> <br></br>
        //           <div
        //             className="output-scr"
        //             dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].outPutRes !== undefined ? outputRes : "" }}
        //           ></div>
        //         </>
        //       ) : (
        //         ""
        //       )
        //     ) : error != "" || error != undefined ? (
        //       <>
        //         {" "}
        //         <label>Error : </label> <br></br>
        //         <div className={!error ? "not-error" : "with-error"}> {testData[questionNo - 1].error !== undefined ? error : ""} </div>
        //       </>
        //     ) : (
        //       ""
        //     )}
        //   </div>
        // ) : 
        language && (language.toLowerCase() === "mysql" || language.toLowerCase() === "sql" || language.toLowerCase() === "pgsql") ? (
          <>
            <div className="col-md-12">
              {col.length > 0 ? (
                <>
                  {outputRes ? (
                    <div className="col-md-12" style={{ padding: "0px", color: "black" }}>
                      <h4 style={{ marginTop: "0px" }}>OUTPUT :</h4>
                    </div>
                  ) : (
                    ""
                  )}
                  <Table className="table">
                    <thead style={{ backgroundColor: "#530b4d" }}>
                      <tr style={{ color: "white" }}>{ThData()}</tr>
                    </thead>
                    <tbody>{tdData()}</tbody>
                  </Table>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row" style={{ height: "70vh", overflow: "scroll" }}>
              <div className="col-12">
                {totalCol.length > 0 ? (
                  <Table className="table">
                    <thead style={{ backgroundColor: "#530b4d" }}>
                      <tr style={{ color: "white" }}>{dataTableTH()}</tr>
                    </thead>
                    <tbody>{dataTableTD()}</tbody>
                  </Table>
                ) : (
                  ""
                )}
              </div>
            </div>
            {error ? (
              <>
                <div className="col-md-12" style={{ padding: "0px", color: "black" }}>
                  <h4 style={{ marginTop: "0px" }}>ERROR :</h4>
                </div>
                <textarea
                  readOnly
                  className={!error ? "not-error" : "with-error"}
                  style={{ overflow: "scroll" }}
                  type="text"
                  rows={error && error.split("\n").length}
                  name="syntaxErrors"
                  id="syntaxErrors"
                  placeholder="Syntax Errors"
                  value={testData[questionNo - 1].error !== undefined ? error : ""}
                />{" "}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <div className="col-md-12" style={{ overflow: "scroll" }}>
            <label>Number of times code compiled: {testData[questionNo - 1].countCompilation !== 0 ? Number(count) : 0} </label>
            <hr></hr>
            {error != "" && error != undefined && !error.includes("python: can't open file") ? (
              <>
                <label>Syntax Errors : </label>
                <br></br>
                {/* <textarea readOnly className={!error ? "not-error" : "with-error"} type="text" rows={error ? error.split(/\r\n|\r|\n/).length : 2} name="syntaxErrors" id="syntaxErrors" placeholder="Syntax Errors" value={testData[questionNo - 1].error !== undefined ? error : ''} /> */}
                <textarea
                  readOnly
                  className={!error ? "not-error" : "with-error"}
                  type="text"
                  rows={error.split("\n").length}
                  name="syntaxErrors"
                  id="syntaxErrors"
                  placeholder="Syntax Errors"
                  value={error !== undefined ? error : ""}
                />
              </>
            ) : (
              ""
            )}
            <hr></hr>

            {/* <label>Logical Output : </label><hr></hr> */}
            {/* <label>Input : </label><br></br>
            <Table className="table table-condensed">
              <thead style={{ backgroundColor: "#6f2981" }}>
                <tr style={{ color: "white" }}>
                  <th>Parameters</th>
                  <th>Data Type</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>string</td>
                  <td>mouse</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>Integer</td>
                  <td>mouse</td>
                </tr>
              </tbody>
            </Table><br></br> */}
            {outputRes != "" && outputRes !== undefined ? (
              <>
                {" "}
                <label>Output :</label>
                <br></br>
                {isloading ? (
                  <Loader />
                ) : language && language.toLowerCase() == "html" ? (
                  <div dangerouslySetInnerHTML={{ __html: `${outputRes}` }}></div>
                ) : (
                  // <textarea readOnly className={!outputRes ? "output-not" : "output-res"} type="text" rows={outputRes ? outputRes.split(/\r\n|\r|\n/).length : 2} name="outputres" id="outputres" placeholder="Output" value={testData[questionNo - 1].outPutRes !== undefined ? outputRes : ''} />
                  <textarea
                    readOnly
                    className={!outputRes ? "output-not" : "output-res"}
                    type="text"
                    rows={outputRes.split("\n").length >= 4 ? outputRes.split("\n").length : 4}
                    name="outputres"
                    id="outputres"
                    placeholder="Output"
                    value={testData[questionNo - 1].outPutRes !== undefined ? outputRes : ""}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};
