import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "../../src/utils/axiosConfig";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CompletedDashboardGrid from "../components/CompletedDashboardGrid";
import { useSelector, useDispatch } from "react-redux";
import { setButtonArray, getButtonArray, getLoading } from "../reducer/dashboardSlice";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  btnPadding: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    fontSize: "0.775rem",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 80,
    padding: theme.spacing(3),
  },
}));
export default function FullWidthGrid(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { buttonName, setButtonClicked } = props;
  // const {handleDownlaodReport, process} = props;
  const buttonArray = useSelector(getButtonArray);
  const loading = useSelector(getLoading);
  const [username, setUsername] = React.useState("");
  const [Organization, setOrganization] = React.useState();
  const [organizationId, setOrganizationId] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();
  const [userId, setUserId] = React.useState();
  const [searchRecord, setSearchRecord] = React.useState(true);
  const [selectTypeButton, setSelectedType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [manualGenerate, setManualGenerate] = React.useState(false);
  const [completedData, setCompleted] = React.useState(false);
  const findOrganizations = () => {
    axios
      .post("/test/searchUserOrg", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        username: username,
      })
      .then((response) => {
        if (response.success == false) {
          setErrorMessage(response.message);
        } else {
          setOrganization(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error findOrganizations", error);
      });
  };

  const onChange = (event) => {
    // dispatch(setButtonArray())
    setUserId();
    setOrganization();
    setErrorMessage();
    setOrganizationId();
    setUsername(event.target.value);
  };

  const searchData = (data) => {
    axios
      .post("/test/searchRecord", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        userId: userId,
        orgId: data !== undefined ? data : organizationId,
      })
      .then((response) => {
        setIsLoading(false);
        dispatch(setButtonArray(response.data.groups));
      })
      .catch((error) => {
        console.log("Error searchRecord", error);
      });
  };

  const handleTestRegeneration = async (data) => {
    console.log("data", data);
    await axios
      .post("/test/regenratetest", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data,
      })
      .then((response) => {
        console.log('response', response)
        if(response.success == false){
            setErrorMessage(response.message)
        }
        console.log(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  React.useEffect(() => {
    setSearchRecord(true);
  }, []);

  React.useEffect(() => {
    if (completedData) {
      searchData(organizationId);
    }
  }, [completedData]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <div className="row" style={{ display: "flex" }}>
          <Grid item xs={6}>
            <b>
              <h3 style={{ color: "#7030a0", fontSize: "20px" }}>Search User Details</h3>
            </b>
          </Grid>
        </div>

        <div className="row">
          <div className="col-md-12" style={{ display: "flex", paddingLeft: "0", paddingRight: "0" }}>
            {/* <div className='col-md-2' style={{ textAlign: 'left', fontSize: 'large', marginTop: '1rem', padding: '0' }}>
                            Enter User E-mail Id :
                        </div> */}
            <div className="col-md-4" style={{ padding: "0" }}>
              <TextField
                style={{ margin: "0" }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="username"
                label="Enter User Email-Id"
                type="username"
                id="username"
                onChange={(event) => onChange(event)}
              />
              {errorMessage ? (
                <div className="username-error" style={{ color: "red" }}>
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-3" style={{ padding: "0", alignSelf: "center", textAlign: "center" }}>
              <Button type="submit" variant="contained" color="primary" className={classes.submit} onClick={findOrganizations}>
                Search User Tests
              </Button>
            </div>
          </div>
          {console.log("org", Organization)}
          {Organization && (
            <>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Org Name</TableCell>
                        <TableCell>Exam Name</TableCell>
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                        <TableCell>Test Status</TableCell>
                        <TableCell>Result Status</TableCell>
                        <TableCell>Percentage</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Organization.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.userexams_id}</TableCell>
                          <TableCell>{row.org_name}</TableCell>
                          <TableCell>{row.exam_name}</TableCell>
                          <TableCell>{row.start_timestamp}</TableCell>
                          <TableCell>{row.finish_timestamp}</TableCell>
                          <TableCell>
                            {row.status == "000"
                              ? "Not Started"
                              : row.status == "001"
                              ? "In Progress"
                              : row.status == "010"
                              ? "Completed"
                              : row.status == "011"
                              ? "Time Out"
                              : row.status == "111"
                              ? "Aborted"
                              : row.status == "999"
                              ? "Result Failed"
                              : row.status == "100"
                              ? "Expired"
                              : null}
                          </TableCell>
                          <TableCell>
                            {row.result_status == "000"
                              ? "Not Started"
                              : row.result_status == "001"
                              ? "In Progress"
                              : row.result_status == "010"
                              ? "Completed"
                              : row.result_status == "011"
                              ? "Time Out"
                              : row.result_status == "111"
                              ? "Aborted"
                              : row.result_status == "999"
                              ? "Result Failed"
                              : row.result_status == "100"
                              ? "Expired"
                              : null}
                          </TableCell>
                          <TableCell>{row.percentage}</TableCell>
                          <TableCell>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleTestRegeneration({ userExamId: row.userexams_id, userId: row.user_id, examInstanceId: row.examinstance_id })
                              }
                            >
                              Regenerate
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}

          {/* {Organization ? (
              <>
                <div className="col-md-4" style={{ textAlign: "right", fontSize: "large", marginTop: "1rem", padding: "0" }}>
                  Select Organization :
                </div>
                <div className="col-md-4">
                  <FormControl style={{ width: "-webkit-fill-available" }}>
                    <InputLabel id="demo-simple-select-helper-label">Organization</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="organization"
                      onChange={handleChange}
                      name="org-select"
                      value={organizationId || ""}
                      options={Organization}
                    >
                      {Organization &&
                        Organization.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.organization_id}>
                              {item.org_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </>
            ) : (
              ""
            )} */}
        </div>
        {organizationId != undefined ? (
          <CompletedDashboardGrid
            setCompleted={setCompleted}
            setManualGenerate={setManualGenerate}
            manualGenerate={manualGenerate}
            dispatch={dispatch}
            buttonArray={buttonArray}
            buttonName={buttonName}
            setButtonClicked={setButtonClicked}
            typeButton={"Completed"}
            loading={isLoading}
            selectTypeButton={"COMPLETED"}
            setSelectedType={"COMPLETED"}
            searchRecord={searchRecord}
            // handleDownlaodReport={handleDownlaodReport} process={process}
          />
        ) : (
          ""
        )}
      </main>
    </>
  );
}
