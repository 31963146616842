import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    title: {
      flexGrow: 1,
    }
}));

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const {testData, questionNo, questionChange, setQuestionChange} = props;
    
    const [hour, setHour] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [second, setSecond] = React.useState(0);
    const [totalTimeSpend,setTotalTimeSpend] = React.useState(testData[questionNo -1].timeSpend ? testData[questionNo -1].timeSpend : 0);
    let timespend = testData[questionNo - 1].timeSpend;

    React.useEffect(() => {
      
      setTimeout(() => {
        if(questionChange){
            
            setQuestionChange(false);
        }
        timespend++;
        setTotalTimeSpend(timespend);
        testData[questionNo - 1].timeSpend = timespend;
        let totalSeconds = timespend;
        setHour(Math.floor(totalSeconds / 3600));
        // totalSeconds %= 3600;
        setMinutes(Math.floor(totalSeconds / 60));
        setSecond(totalSeconds % 60);
      }, 1000);
    });

    
    return(
        // <strong>Time Spent: {`${hour.toString().length === 1 ? '0'+hour : hour} : ${minutes.toString().length === 1 ? '0'+minutes : minutes} : ${second.toString().length === 1 ? '0'+second : second}`}</strong>
            //  <p>Time Spent: <span>{`${hour.toString().length === 1 ? '0' + hour : hour} : ${minutes.toString().length === 1 ? '0' + minutes : minutes} : ${second.toString().length === 1 ? '0' + second : second}`}</span></p>
                    <p>Time Spent: <span>{new Date(totalTimeSpend * 1000).toISOString().slice(11, 19)}</span></p>
    );

}
