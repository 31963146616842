import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
// import Paper from "@material-ui/core/Paper";
import cache from "memory-cache";
import { FormControlLabel, FormGroup, Popover, Typography } from "@material-ui/core";
import { WEB_URL } from "../../../utils/constants";
import onWindowReload from '../../../utils/reloadFunction';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from "./v1-links";
import { translationMissingMessage } from "./translationConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
 paper: {
      padding:'0px 5px',
    },
  popover: {
    pointerEvents: "none",
  },
}));

//   const CheckboxGroup = ({ fields, options }) => {
//     const toggle = (event, option) => {
//       if (event.target.checked) fields.push(option);
//       else fields.remove(option);
//     };
//     return (
//       <div style={{ color: "blue" }}>
//         {options.map(option => (
//           <div key={option}>
//             <input
//               type="checkbox"
//               onClick={event => toggle(event, option)}
//             />
//             {option}
//           </div>
//         ))}
//       </div>
//     );
//   };

export default (props) => {
  const classes = useStyles();

  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    disable,
    setDisable,
    setTestData,
    windowSwitchCount,setWindowSwitch
  } = props;
  const [selectAnswer, setAnswer] = React.useState("");
  const opt = [];
  const [myOptions, setMyOptions] = React.useState(
    testData[questionNo - 1].selectedChoiceId.match(/\d+/g)
      ? testData[questionNo - 1].choiceid.replace("|^^|", ",")
      : ""
  ); //testData[questionNo -1].selectedChoiceId
  const [checkArray, setCheckArray] = React.useState(
    testData[questionNo - 1].selectedChoiceId
  );
  // const myOptions = testData[questionNo -1].choiceid.replace('|^^|',',');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)


  const open = Boolean(anchorEl);

  const getAnswer = (values) => {
    
    //testData[questionNo -1].choiceid.split('|^^|')[testData[questionNo -1].choicetext.split('|^^|').indexOf(event.target.value)] ;
    if (testData[questionNo - 1].selectedChoiceId.match(/\d+/g)) {
      //  setAnswer(testData[questionNo -1].selectedChoiceId);
      if (testData[questionNo - 1].selectedChoiceId.indexOf("null") == -1) {
        testData[questionNo - 1].buttonStyle = "Answered";
      }
    }
    
  };

  const CheckboxGroupMUI = () => {

    if (testData[questionNo - 1].selectedChoiceId == "") {
      let data = checkArray.split(","); 
      while (data.length > 0) {
        data.pop();
      }
    }

    const toggle = (event, choiceid) => {
      let fields = testData[questionNo - 1].selectedChoiceId.split(",");
      
      if (event.target.checked) {
        fields.push(choiceid);
      } else {
        fields.splice(fields.indexOf(choiceid), 1)
      }

    
      // let arr = fields;
      // fields.map((item) => {
      //   if(checkArray.indexOf(testData[questionNo - 1].choiceid.split("|^^|")[
      //     testData[questionNo - 1].choicetext.split("|^^|").indexOf(item)
      //   ]) == -1) {
      //     arr.push(
      //       testData[questionNo - 1].choiceid.split("|^^|")[
      //         testData[questionNo - 1].choicetext.split("|^^|").indexOf(item)
      //       ]
      //     );
      //   }
      // });

      
      setClearFlag(false);
      // setAnswer(arr);

      testData[questionNo - 1].selectedChoiceId = fields.toString();
      setCheckArray(fields.toString());
    };

    //   const check = (event, option) => {
    //     
    //   if (event.target.checked) {
    //       fields.push(option);
    //     }
    //     else if(testData[questionNo -1].selectedChoiceId.match(/\d+/g)){
    //       fields.push
    //     }
    //   else{
    //     fields.remove(option);
    //   }
    // };

    //  testData[questionNo -1].selectedChoiceId.split(',').indexOf(testData[questionNo -1].choiceid.split('|^^|')[index]) !== -1
    getAnswer(checkArray);
    return (
      <FormGroup row={false}>
        {testData[questionNo - 1].choicetext
          .split("|^^|")
          .map((item, index) => (
            <FormControlLabel
              key={item}
              //label={<span dangerouslySetInnerHTML={{__html: item}} style={{color:testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)] === item  ? "#fff" : "#525252" }} />}
                            
              label={<>
              {!((testData[questionNo -1].chresourcepath.split('|^^|')[index] !== undefined && testData[questionNo -1].chresourcepath.split('|^^|')[index] !== null && testData[questionNo -1].chresourcepath.split('|^^|')[index] !== "") ||
                (testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "")) ? (
                  <span>
                    {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_choicetext.split("|^^|")[index] == "--null--") ? <span dangerouslySetInnerHTML={{ __html: item }} /> : null}
                    {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_choicetext.split("|^^|")[index] == "--null--") ? <><br /><br /></> : null}
                    {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].translated_choicetext.split("|^^|")[index] != "--null--" ? testData[questionNo - 1].translated_choicetext.split("|^^|")[index] : translationMissingMessage, fontSize: testData[questionNo - 1].translated_choicetext.split("|^^|")[index] != "--null--" ? "" : "10px" }} /> : null}
                  </span>
                ) : (
                <div>
                  <br />
                  {supportedFileExtensions.image.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                    <img
                      src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                        testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                        :
                        v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.audio.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                      <audio
                        controls
                        src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                          testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                          :
                          v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                        }
                        key={testData[questionNo -1].qid}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      : supportedFileExtensions.video.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                        <video
                          controls
                          src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                            testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                            :
                            v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                          }
                          key={testData[questionNo -1].qid}
                          style={{ maxWidth: "100%" }}
                          alt=""
                        />
                        : supportedFileExtensions.file.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                          <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                            testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                            :
                            v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                          }
                            download>CLICK TO DOWNLOAD</a>
                          :
                          null
                  }
                </div>
              )}</>}
              control={
                <Checkbox
                  value={item}
                  onChange={(event) => toggle(event,  testData[questionNo - 1].choiceid.split("|^^|")[index])}
                  //checked = {testData[questionNo -1].selectedChoiceId.split(',')[testData[questionNo -1].choicetext] !=='' ?testData[questionNo -1].selectedChoiceId.split(',')[testData[questionNo -1].choicetext.split('|^^|')].indexOf(item):false}
                  checked={
                    testData[questionNo - 1].selectedChoiceId
                          .split(",")
                          .indexOf(
                            testData[questionNo - 1].choiceid.split("|^^|")[index]
                          ) !== -1
                     
                  }
                />
              }
            />
          ))}
      </FormGroup>
    );
  };
  React.useEffect(() => {
    
    if (questionNo === testData.length) {
     

      setDisable(true);
    }
    

    if (clearFlag) {
      testData[questionNo - 1].selectedChoiceId = "";
      setAnswer("");
      setMyOptions("");
      setCheckArray("");
      let temp = answerStatus;
      //temp[questionNo -1] = 0;
      setAnswerStatus(temp);
      testData[questionNo - 1].buttonStyle = "Not Answered";
      
      let collectData = {quesData: testData, examDetail: examDetail}
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      window.localStorage.setItem(examId, encode);
      
      setClearFlag(false);
    }
    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }, [
    clearFlag,
    myOptions,
    selectAnswer,
    questionNo,
    answerStatus,
    checkArray,
  ]);

 
  return (
    <div>
      <>
        {/* <strong> Question No {questionNo}</strong>< br/>  */}
        <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span className="question-num">Q.{questionNo} of {testData.length}</span>
        </Typography>
        {/* <Button> Question No {questionNo}</Button>< br/>  */}
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          PaperProps={{
            style: { height: '4%' ,textAlign:"center"},
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{testData[questionNo - 1].qid}</Typography>
        </Popover>
        {examDetail[0].comprehensionflag && testData[questionNo - 1].passageheader  ?  
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].passageheader }}/> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passageheader || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_passageheader ? "" : "10px" }} /> : null}</> :
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}</>} 
        {/* {testData[questionNo -1].qtext} */}
        {/* <span style={{fontSize:"16px"}} 
          dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }}
        />
        {((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
          (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
          <div>
            <br />
            {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
              <img
                src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                  testData[questionNo -1].question_resource_url.split('|^^|')[0]
                  :
                  v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                }
                key={testData[questionNo -1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
              : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                <audio
                  controls
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <video
                    controls
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                      download>CLICK TO DOWNLOAD</a>
                    :
                    null
            }
          </div>
        ) : (
          <></>
        )} */}

<div className={!examDetail[0].comprehensionflag && testData[questionNo -1].passagetext  ? "row ":"row paraque-box"}>
      {examDetail[0].comprehensionflag && testData[questionNo -1].passagetext ?
        <div className="col-md-6">
          <p>
            <b>Passage</b>
          </p>
          <div className="question-para" style={{fontSize: '16px',textAlign: 'justify'}}>
          {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passagetext == null) ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].passagetext}} /> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passagetext == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].translated_passagetext || translationMissingMessage}} style={{ fontSize: testData[questionNo - 1].translated_passagetext ? "" : "10px" }} /> : null}
          </div>
          {/* Need to check here (Dhaval) */}
          {((testData[questionNo -1].passageImage !== undefined && testData[questionNo -1].passageImage !== null && testData[questionNo -1].passageImage !== "") ||
            (testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "")) ? (
            <div>
              <br />
              {supportedFileExtensions.image.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                    testData[questionNo -1].passageImageURL
                    :
                    v1_question_images_link + testData[questionNo -1].passageImage
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                      testData[questionNo -1].passageImageURL
                      :
                      v1_question_images_link + testData[questionNo -1].passageImage
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
              <br/><br />
            </div>
          ) : (
            <></>
          )}
        </div>
        : ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
            (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
            <div className="col-md-6">
              {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
            </div>
          ) : (
            <></>
          )

                 }

            
        <div className={examDetail[0].comprehensionflag ?  "col-md-6": examDetail[0].instancename === "Multi Choice Single Correct" ? "col-md-12" : "col-md-6"} style={{width: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Multiple Choice Single Correct' && testData[questionNo -1].qresourcepath === '' && '100%', paddingTop: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Multiple Choice Single Correct' && testData[questionNo -1].qresourcepath === '' && '20px'}}>
        {examDetail[0].comprehensionflag  && testData[questionNo -1].passagetext? <p><b>Question</b></p>:" "}
          <div className="form-group question-right">
          {examDetail[0].comprehensionflag && testData[questionNo -1].passagetext ? 
          <>
            
            <label className="control-label">
              
            {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
            </label> 
            {
              ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
                (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
                <div>
                  {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <img
                      src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                      <audio
                        controls
                        src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                          testData[questionNo -1].question_resource_url.split('|^^|')[0]
                          :
                          v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                        }
                        key={testData[questionNo -1].qid}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                        <video
                          controls
                          src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                            testData[questionNo -1].question_resource_url.split('|^^|')[0]
                            :
                            v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                          }
                          key={testData[questionNo -1].qid}
                          style={{ maxWidth: "100%" }}
                          alt=""
                        />
                        : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                          <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                            testData[questionNo -1].question_resource_url.split('|^^|')[0]
                            :
                            v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                          }
                            download>CLICK TO DOWNLOAD</a>
                          :
                          null
                  }
                  <br/><br />
                </div>
              ) : (
                <></>
              )
            }
            </>:<></>}
            
            <br />
            <FormControl component="fieldset">
              <CheckboxGroupMUI />
            </FormControl>      
            </div>
        </div>
        </div>
      </>
    </div>
  );
};
