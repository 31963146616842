// new code of Video Interview
import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Typography,
  Popover,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  CircularProgress,
  DialogContentText
} from "@material-ui/core";
import cache from "memory-cache";
import axios from "../../../utils/axiosConfig";
import * as faceapi from "face-api.js";
import Cookies from "js-cookie";
import DashboardGrid from "../../DashboardGrid/dashboardgrid";
import moment from "moment";
import RecordRTC from "recordrtc";
import onWindowReload from "../../../utils/reloadFunction";
import SpeechToText from "speech-to-text";
import { WEB_URL } from "../../../utils/constants";
import supportedFileExtensions from "./supportedFileExtensions";
import { v1_question_images_link } from "./v1-links";
import { useSelector } from "react-redux";
import { getOrgId } from "../../../reducer/dashboardSlice";
import { serverConstants, localhostConstants } from "../../../utils/serverConstants";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { translationMissingMessage } from "./translationConstants";
import { PlayArrow, Stop } from "@material-ui/icons";
import { proctoringURL } from "../../../utils/constants";

const images = [];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: "0px 5px",
  },
  popover: {
    pointerEvents: "none",
  },
  videodiv: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#D32F2F", // Adjusted shade of red for hover state
    "&:disabled": {
      backgroundColor: "#A9A9A9", // Change the background color to indicate disabled state
      color: "#ffffff", // Change the text color to indicate disabled state
      cursor: "not-allowed", // Change cursor to indicate not clickable
    },
    "&:hover": {
      color: "#ffffff",
      background: "#FF5733",
    },
  },
  container: {
    marginTop: "3px",
    width: "100%",
    maxWidth: "100vw",
    height: "60%",
  },
  video: {
    width: "100%", // Ensure the video takes the full width of its container
    height: "100%", // Ensure the video takes the full height of its container
    objectFit: "contain", // Maintain aspect ratio without stretching
    // aspectRatio: 16 / 9,
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    // Media queries for adjusting width and height
    [theme.breakpoints.up("sm")]: {
      width: "70%", // For medium-sized screens and larger
    },
    [theme.breakpoints.up("md")]: {
      width: "60%", // For large-sized screens and larger
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%", // For extra-large-sized screens
    },
  },
  dialog: {
    // Add your custom styles here
    // For example:
    "& .MuiDialog-paper": {
      // Styling for the dialog paper
      backgroundColor: "#f0f0f0", // Example background color
      padding: theme.spacing(2), // Example padding
    },
    "& .MuiDialogTitle-root": {
      // Styling for the dialog title
      backgroundColor: "#ccc", // Example background color
    },
    "& .MuiDialogContent-root": {
      // Styling for the dialog content
      padding: theme.spacing(2), // Example padding
    },
    "& .MuiDialogActions-root": {
      // Styling for the dialog actions
      justifyContent: "space-between", // Example: Align actions to the space between
    },
    // Add more styles as needed...
  },
  timerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2e6f9", // Light background color
    borderRadius: "50%",
    width: "150px", // Adjust as needed
    height: "150px", // Adjust as needed
    boxShadow: "0 4px 15px rgba(111, 41, 129, 0.5)", // Shadow with theme color
    marginBottom: "20px",
  },
  timer: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "#6f2981", // Theme color for text
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
}));

export default (props) => {
  const classes = useStyles();
  let token = Cookies.get("token");
  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    marksScored,
    setMarksScored,
    totalMarks,
    setTotalMarks,
    wrongAnswerCount,
    setWrongAnswerCount,
    disable,
    setDisable,
    setTestData,
    windowSwitchCount,
    setWindowSwitch,
    isRecording,
    setIsRecording,
    isVideoSent,
    setIsVideoSent,
    isVideoUploading,
    setIsVideoUploading,
    isTimeUp,
    setIsTimeUp,
    isRecordingDone,
    setIsRecordingDone
  } = props;

  const [error, setError] = useState(false);
  const [selectAnswer, setAnswer] = React.useState("");
  const [recordVideo, setRecordVideo] = React.useState(null);
  const [adminToken, setAdminToken] = React.useState("");
  const [interimText, setInterimText] = useState("");
  const [finalisedText, setFinalisedText] = useState([]);
  const [listening, setListening] = useState(false);
  const [language, setLanguage] = useState(examDetail[0].speechlang + "-IN");
  const [emojiValue, setEmojiValue] = useState(0);
  const [listener, setListener] = React.useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userid, setUserId] = useState("");
  const [filename, setFilename] = useState("");
  const [blobUrl, setBlobUrl] = useState("");
  const [display, setDisplay] = useState(false);
  const [facialDataArray, setFacialDataArray] = useState([]);
  const [big5, setBig5] = useState("");
  const [facialSentiment, setFacialSentiment] = useState({});
  const [sentiment, setSentiment] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = React.useState(testData[questionNo - 1].descriptiveText !== null);
  const [isVideoRecorded, setIsVideoRecorded] = useState(false);
  const [uploadedVideoLink, setUploadedVideoLink] = useState("");
  const [retryClicked, setRetryClicked] = React.useState(false);
  const [uploadingStatus, setUploadingStatus] = React.useState({});
  const [checkArray, setCheckArray] = React.useState(testData[questionNo - 1].selectedChoiceId);
  const [startRecordingCountdown, setStartRecordingCountdown] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0); // State to store the recording time
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  const [recordedTime, setRecordedTime] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [stopRecordingClicked, setStopRecordingClicked] = useState(false);
  const [facialAnalysisCleanup, setFacialAnalysisCleanup] = useState(null);
  const [userImg, setUserImg] = useState("");
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [myOptions, setMyOptions] = React.useState(
    testData[questionNo - 1].selectedChoiceId.match(/\d+/g) ? testData[questionNo - 1].choiceid.replace("|^^|", ",") : ""
  );

  // Load the facial models
  React.useEffect(() => {
    const loadModels = async () => {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(proctoringURL +"/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri(proctoringURL +"/models"),
        faceapi.nets.faceRecognitionNet.loadFromUri(proctoringURL +"/models"),
        faceapi.nets.faceExpressionNet.loadFromUri(proctoringURL +"/models"),
      ]);
    };
    loadModels();
  }, []);

  // Function to start the countdown and then initiate recording
  const startRecordingWithCountdown = () => {
    setStartRecordingCountdown(true);
    // After 3 seconds, start recording
    setTimeout(() => {
      startRecording();
      setStartRecordingCountdown(false);
    }, 3000);
  };

  // Function to start recording with confirmation
  const startRecordingWithConfirmation = () => {
    setShowConfirmation(true);
  };

  // Function to handle confirmation
  const handleConfirmation = (confirm) => {
    setShowConfirmation(false);
    setStopRecordingClicked(true);
    if (confirm) {
      startRecordingWithCountdown();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRetryRecording = () => {
    setShowModal(false);
    // Start the recording again
  };

  // Function to render the countdown component
  const renderCountdown = ({ remainingTime }) => {
    remainingTime = remainingTime || 0; // Default to 0 if remainingTime is undefined
    return (
      <div className={classes.timerContainer}>
        <CountdownCircleTimer
          size={140}
          isPlaying={true} // Replace with your state variable e.g., startRecordingCountdown
          duration={3} // Countdown duration in seconds
          colors={["#6f2981", "#9b59b6", "#d2b4de", "#f2e6f9"]}
          colorsTime={[3, 2, 1, 0]}
          onComplete={() => console.log("Countdown Complete")} // Replace with your handler
        >
          {({ remainingTime }) => <div className={classes.timer}>{remainingTime !== undefined ? remainingTime : ""}</div>}
        </CountdownCircleTimer>
      </div>
    );
  };

  // Create a reference for the user's video feed
  const userVideoRef = useRef(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Function to handle the window reload event
  onWindowReload(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData);

  const open = Boolean(anchorEl);

  const getAnswer = (values) => {
    //testData[questionNo -1].choiceid.split('|^^|')[testData[questionNo -1].choicetext.split('|^^|').indexOf(event.target.value)] ;
    if (testData[questionNo - 1].selectedChoiceId.match(/\d+/g)) {
      //  setAnswer(testData[questionNo -1].selectedChoiceId);
      if (testData[questionNo - 1].selectedChoiceId.indexOf("null") == -1) {
        testData[questionNo - 1].buttonStyle = "Answered";
      }
    }
  };

  // facial analysis
  const captureFacialSentiments = async () => {
    if (userVideoRef.current) {
      const detections = await faceapi.detectAllFaces(userVideoRef.current, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions();

      if (detections.length > 0) {
        const sentiments  = detections[0].expressions;
        const timestamp = new Date().toISOString();
        setFacialDataArray(prevArray => [...prevArray, { timestamp, sentiments }]);
      }
    }
  };


  const startRecording = async () => {
    // console.log("Start recording Button hit ");
    setFacialDataArray([]);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 640 },
          height: { ideal: 480 },
          frameRate: { ideal: 15 },
        },
        audio: true,
      });
      // Log available tracks to debug
      // stream.getTracks().forEach(track => {
      //   console.log(track.kind, track);
      // });
      const mediaRecorder = new RecordRTC(stream, {
        type: "video",
        mimeType: "video/mp4",
        video: { width: 640, height: 480 },
        frameRate: 15,
        bitsPerSecond: 512000,
      });
      mediaRecorder.startRecording();
      // Update recordVideo state using functional update form
      setRecordVideo((prev) => mediaRecorder);
      setIsRecording(true);
      setIsVideoSent(true);
      setUploadingStatus((prev) => ({ ...prev, [questionNo]: { uploading: false, success: false } }));

      // Attach the user's video stream to the userVideoRef if it exists
      if (userVideoRef.current) {
        userVideoRef.current.srcObject = stream;
      }

      // Mute the video element to prevent echo
      const videoElement = document.createElement("video");
      videoElement.srcObject = stream;
      videoElement.muted = true; // Mute the video element to prevent audio feedback
      videoElement.play();

      // Wait for the video to be ready
      await new Promise((resolve) => {
        videoElement.onloadedmetadata = () => {
          resolve();
        };
      });

      // Create a canvas and draw the current frame
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      canvas.getContext("2d").drawImage(videoElement, 0, 0);

      // Convert the canvas to a blob
      const imageBlob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
      const imageName = `${props.examDetail[0].userid}_${props.examId}_${props.testData[props.questionNo - 1].qid}.jpg`;

      // Create FormData and append the image
      const formData = new FormData();
      formData.append("file", imageBlob, imageName);

      // Upload the image
      try {
        const response = await axios.post("/result/uploadImg", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setUserImg(response.data.imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }

      // Start capturing facial sentiments every 5 seconds
      const facialAnalysisInterval = setInterval(captureFacialSentiments, 5000);
      setFacialAnalysisCleanup(facialAnalysisInterval);

      // Start the recording timer
      const startTime = Date.now();
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - startTime) / 1000); // Convert to seconds
        if (elapsedTime >= 91) { // Stop the timer after 90 seconds
          clearInterval(intervalId);
          console.log("Recording time reached 1.30 min, stopping timer.");
        } else {
          const recordedTime = formatRecordingTime(elapsedTime);
          setRecordingTime(recordedTime);
        }
      }, 1000); // Update every second

      // Store the interval ID in state
      setTimerInterval(intervalId);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  // stop recording with upload video on azure API -->
  const stopRecording = async () => {
    clearInterval(facialAnalysisCleanup);
    clearInterval(timerInterval); // Stop the timer
    if (recordVideo) {
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: true, success: false },
      }));
      recordVideo.stopRecording(async () => {
        const blob = recordVideo.getBlob();
        const url = URL.createObjectURL(blob);
        const currentRecordingTime = recordingTime; // Save the current recording time

        // Save the recorded time in state
        setRecordedTime(currentRecordingTime);
        // console.log("Recorded Time --> ", currentRecordingTime);
        setBlobUrl(url);
        setIsRecording(false);

        const filename = `${props.examDetail[0].userid}_${props.examId}_${testData[questionNo - 1].qid}.mp4`;

        // Check if the recording time is less than 20 seconds
        if (currentRecordingTime < "00.20 min") {
          setShowModal(true); // Show the modal
          setUploadingStatus((prev) => ({
            ...prev,
            [questionNo]: { uploading: false, success: false },
          }));
          setIsVideoSent(false);
          return; // Exit the function without uploading the video
        }

        if (currentRecordingTime >= "00.20 min") {
          setIsStartButtonDisabled(true);
        }

        // Create a FormData object to send the video file to the server
        const formData = new FormData();
        formData.append("video", blob, filename); // Pass the filename here
        setIsVideoUploading(true);
        // Make a POST request to your backend API endpoint
        axios({
          method: "POST",
          url: "/result/uploadvideo",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            // Handle the response from the API
            const { videoUrl, file_name } = response.data;

            // Update the answerResourcePath in your testData
            testData[questionNo - 1].answerResourcePath = videoUrl; // Store the video URL in answerResourcePath
            testData[questionNo - 1].answerResourceName = file_name;
            testData[questionNo - 1].descriptiveText = "Not Required";
            testData[questionNo - 1].selectedChoiceId = "1";
            testData[questionNo - 1].recordedTime = currentRecordingTime;
            testData[questionNo - 1].userImage = userImg;
            testData[questionNo - 1].facialData = facialDataArray;

            // Set the uploaded video link to the state
            setUploadedVideoLink(videoUrl);
            // console.log("video uploaded successfully --> ", videoUrl);
            setIsVideoUploading(false);

            // Now you can use the videoUrl as the URL of the uploaded video file
            // console.log("Video file uploaded successfully:", videoUrl);
            setUploadingStatus((prev) => ({
              ...prev,
              [questionNo]: { uploading: false, success: true },
            }));
            setIsVideoRecorded(true);
            setIsVideoSent(false);
            setCheckFlag(true);
            setRecordingTime(0);
            setIsRecordingDone(true);
          })
          .catch((error) => {
            console.error("Error uploading video file on azure:", error);
            const errorMessage = error.message || "Unknown error occurred";
            setIsVideoUploading(false)
            setUploadingStatus((prev) => ({
              ...prev,
              [questionNo]: { uploading: false, success: false },
            }));
            setIsVideoSent(false);


            // Option to retry
            const shouldRetry = window.confirm("Video upload failed. Do you want to retry?");
            if (shouldRetry) {
              // Set the retryClicked state to true
              setRetryClicked(true);
              // You can add additional logic here if needed
            } else {
              // Reject the promise with the original error message
              return Promise.reject(errorMessage);
            }
          });
      });
    }
  };

  // console.log("facialData -->", facialDataArray);

  // Function to format recording time from seconds to "00.00 min"
  const formatRecordingTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}.${seconds < 10 ? "0" + seconds : seconds} min`;
  };
  const parseTimeString = (time) => {
    if (typeof time === 'number') {
      return time; // Already in seconds
    }
    if (typeof time === 'string') {
      // Remove 'min' and split by '.'
      const [minutes, seconds] = time.replace(' min', '').split('.').map(Number);
      return (minutes * 60) + seconds;
    }
    console.error('Unexpected time format:', time);
    return 0; // Return 0 if the format is unexpected
  };
  React.useEffect(() => {
    const recordingTimeInSeconds = parseTimeString(recordingTime);

    if (isTimeUp && isRecording && recordingTimeInSeconds >= 20) {
      stopRecording();
    }
    else if (isTimeUp && isRecording && recordingTimeInSeconds < 20) {
      setIsRecordingDone(true);
    }
    else if (isTimeUp && !isRecording) {
      setIsRecordingDone(true);
    }
  }, [isTimeUp, recordingTime]);

  React.useEffect(() => {
    if (recordingTime === "01.30 min") {
      stopRecording();
    }
  }, [recordingTime]);

  const playRecordedVideo = () => {
    if (blobUrl) {
      const video = document.getElementById("recordedVideo");
      video.src = blobUrl;
      video.play();
    }
  };

  const handleRecordingButton = () => {
    setStopRecordingClicked(true);
    if (isRecording) {
      stopRecording();
    } else if (blobUrl) {
      playRecordedVideo();
    } else {
      startRecording();
    }
  };

  const orgId = useSelector(getOrgId);

  // Reset recording and related states when moving to the next question
  React.useEffect(() => {
    setIsRecordingDone(false);
    setUserImg("");
    setFacialDataArray([]);
    setIsStartButtonDisabled(false);
    setIsVideoUploading(false)
    if (questionNo === testData.length) {
      setDisable(true);
    }
    if (orgId != undefined && orgId != "") {
      try {
        axios
          .get("/dashboard/getProfile", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              orgId: orgId,
            },
          })
          .then((profData) => {
            let userData = profData.data.profileData;
            setFirstName(userData.firstname);
            setLastName(userData.lastname);
            setFilename(`${firstName}_${lastName}_${props.examId}_${testData[questionNo - 1].qid}.mp4`);
          })
          .catch((error) => {
            // Handle error
          });
      } catch (error) {
        // Handle error
      }
    }
    // onAnswerText(answer);
    setBlobUrl(`${WEB_URL}ah-resources/user-files/autoSpeech/` + filename);
    if (clearFlag) {
      setAnswer("");
      setMyOptions("");
      setCheckArray("");
      let temp = answerStatus;
      //temp[questionNo -1] = 0;
      setAnswerStatus(temp);
      testData[questionNo - 1].buttonStyle = "Not Answered";
      let collectData = { quesData: testData, examDetail: examDetail };
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
      window.localStorage.setItem(examId, encode);
      setClearFlag(false);
    }
    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);

    // Reset recording states here
    setRecordVideo(null);
    setBlobUrl("");
    // setIsRecording(false);

    // Clear the user's video feed when not recording
    if (!isVideoRecorded) {
      if (userVideoRef.current) {
        const stream = userVideoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    }
    setStopRecordingClicked(false);
  }, [clearFlag, blobUrl, finalisedText, filename, questionNo, myOptions, selectAnswer, answerStatus, checkArray]);

  getAnswer(checkArray);
  return (
    <div>
      <>
        <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span className="question-num">
            Q .{questionNo} of {testData.length}
          </span>
        </Typography>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{testData[questionNo - 1].qid}</Typography>
        </Popover>
        {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? (
          <span
            dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }}
            style={{ fontWeight: "bold", fontSize: "1.2em", color: "#333" }}
          />
        ) : null}
        {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
        {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? (
          <span
            dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].translated_qtext || translationMissingMessage }}
            style={{ fontWeight: "bold", fontSize: testData[questionNo - 1].translated_qtext ? "1.2em" : "10px", color: "#333" }}
          />
        ) : null}
        {(testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== undefined &&
          testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== null &&
          testData[questionNo - 1].qresourcepath.split("|^^|")[0] !== "") ||
        (testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
          testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
          testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== "") ? (
          <div>
            {supportedFileExtensions.image.includes(
              testData[questionNo - 1].qresourcepath
                .split("|^^|")[0]
                .split(".")
                [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
            ) ? (
              <img
                src={
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                    ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                    : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
                }
                key={testData[questionNo - 1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
            ) : supportedFileExtensions.audio.includes(
                testData[questionNo - 1].qresourcepath
                  .split("|^^|")[0]
                  .split(".")
                  [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
              ) ? (
              <audio
                controls
                src={
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                    ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                    : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
                }
                key={testData[questionNo - 1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
            ) : supportedFileExtensions.video.includes(
                testData[questionNo - 1].qresourcepath
                  .split("|^^|")[0]
                  .split(".")
                  [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
              ) ? (
              <video
                controls
                src={
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                    ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                    : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
                }
                key={testData[questionNo - 1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
            ) : supportedFileExtensions.file.includes(
                testData[questionNo - 1].qresourcepath
                  .split("|^^|")[0]
                  .split(".")
                  [testData[questionNo - 1].qresourcepath.split("|^^|")[0].split(".").length - 1].toLowerCase()
              ) ? (
              <a
                style={{ color: "#337ab7", fontSize: "16px" }}
                href={
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
                  testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
                    ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
                    : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
                }
                download
              >
                CLICK TO DOWNLOAD
              </a>
            ) : null}
          </div>
        ) : (
          <></>
        )}
        {/* <audio src={`${WEB_URL}ah-resources/questionimages/` + testData[questionNo - 1].qresourcepath} controls="controls" /> */}
        <div className={classes.container}>
          {!isRecording ? (
            <>
              <div className="videodiv">
                {/* Render countdown here instead of video */}
                {startRecordingCountdown ? (
                  renderCountdown({ remainingTime: 0 }) // Pass a default value for remainingTime
                ) : (
                  <video
                    id="recordedVideo"
                    src={`${
                      serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
                        ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).azureStorageURL
                        : localhostConstants.azureStorageURL
                    }${testData[questionNo - 1].answerResourceName}`}
                    controls="controls"
                    className={classes.video}
                  />
                )}
              </div>
              {!startRecordingCountdown ? (
                <>
                  {uploadingStatus[questionNo] &&
                  (uploadingStatus[questionNo].uploading || stopRecordingClicked) &&
                  !uploadingStatus[questionNo].success ? (
                    <>
                    {/* {setIsVideoUploading(true)} */}
                      <p style={{ color: "#333", marginTop: "8px", fontSize: "16px", fontWeight: "bold" }}>
                        <span>
                          <CircularProgress style={{ height: "14px", width: "14px", marginRight: "4px", color: "#6f2981" }} color="secondary" />
                        </span>{" "}
                        Video is uploading...
                      </p>
                      <LinearProgress color="#6f2981" />
                    </>
                  ) : testData[questionNo - 1].answerResourceName || testData[questionNo - 1].disabled ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                        <Typography variant="body2" gutterBottom style={{ margin: "1px", marginRight: "16px" }}>
                          Recorded Time: <b>{testData[questionNo - 1].recordedTime}</b>
                        </Typography>
                      </div>
                      <Typography style={{ color: "#4CAF50", fontSize: "16px", fontWeight: "bold", margin: "0" }}>
                        {/* {setIsVideoUploading(false)} */}
                        Video saved successfully !
                      </Typography>
                    </>
                  ) : null}
                  <Button
                    onClick={startRecordingWithConfirmation}
                    disabled={isStartButtonDisabled || testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled}
                    className={classes.button}
                    startIcon={<PlayArrow />}
                    size="large"
                  >
                    Start Recording
                  </Button>

                  {/* dialog for confirmation */}
                  <Dialog open={showConfirmation} onClose={() => handleConfirmation(false)}>
                    <DialogTitle style={{ color: "#6f2981" }}>Recording Limit:</DialogTitle>
                    <DialogContent>
                      <Typography>
                        You have to record for{" "}
                        <span style={{ color: "#6f2981" }}>
                          <b>
                            AT LEAST <u>20</u> seconds
                          </b>
                        </span>{" "}
                        to successfully complete this question.
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleConfirmation(false)} variant="outlined">
                        Cancel
                      </Button>
                      <Button onClick={() => handleConfirmation(true)} variant="contained" color="primary">
                        Proceed
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* Dialog for Re-record */}
                  <Dialog open={showModal} onClose={handleCloseModal}>
                    <DialogTitle>Recording Duration Too Short</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        You video recording should be{" "}
                        <span style={{ color: "#6f2981" }}>
                          <b>
                            AT LEAST <u>20</u> seconds
                          </b>
                        </span>{" "}
                        long to move forward.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseModal} variant="outlined">
                        Cancel
                      </Button>
                      <Button onClick={handleRetryRecording} variant="contained" color="primary" autoFocus>
                        Retry
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ) : null}
            </>
          ) : (
            <div className={classes.container}>
              {/* Render countdown here instead of video */}
              {
                <div className="videodiv">
                  <video id="userVideo" ref={userVideoRef} className={classes.video} autoPlay muted />
                  <Typography variant="body2" gutterBottom style={{ margin: "1px" }}>
                    Recording Time: <span style={{ color: "#6f2981" }}>{recordingTime}</span>
                  </Typography>
                </div>
              }
              <>
                <p>Click below button to stop recording</p>
                <div>
                  <Button size="large" onClick={handleRecordingButton} className={classes.button} startIcon={<Stop />}>
                    <b>Stop Recording</b>
                  </Button>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
