import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Question from "../../TestPassage/question";
// import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import cache from "memory-cache";
import { Popover, Typography } from "@material-ui/core";
import { WEB_URL } from "../../../utils/constants";
import onWindowReload from '../../../utils/reloadFunction';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from "./v1-links";
import { translationMissingMessage } from "./translationConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    display :"flex",
    borderRadius: "15px",
    border:"2px solid #6f2981 ",
    width: "15px",
    height: "15px",
    position:"relative",
    visibility:"visible",
    top:"0px",
    left:"-15px",
    padding:"10px", 
    textAlign:"justify",
  },
  icon: {
    display:'none',
    borderRadius: "15px",
    border:"1px solid #6f2981 ",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      background:"#fff",
      backgroundColor: "#fff",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    display:"flex",
    top:"-15px",
    left:"-6px",
    padding:"3px",
    borderRadius: "15px",
    background:"#6f2981",
    border:"2px solid #fff",
    backgroundImage:
      "radial-gradient(#fff,#fff 28%,transparent 32%)",
    "&:before": {
      display: "block",
      width: "15px",
      height:"15px",
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding:'0px 5px',
  },
}));

export default (props) => {
  const classes = useStyles();
  // const theme = useTheme();
  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    // disable,
    setDisable,
    setTestData,
    windowSwitchCount,setWindowSwitch
  } = props;

  const optionData = testData && testData[questionNo - 1]
  const currentQueOption = {choiceid : optionData.choiceid.split("|^^|"), label:optionData.choicetext.split("|^^|"), translated_label:examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? optionData.translated_choicetext.split("|^^|") : null}
  
  const [selectAnswer, setAnswer] = React.useState(""); //testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)]
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  const open = Boolean(anchorEl);
  const getAnswer = (event) => {
    if(event.target.value){
    setClearFlag(false);
    setCheckFlag(true);
    setAnswer(event.target.value);
    let idx = currentQueOption.choiceid.indexOf(event.target.value)
    testData[questionNo - 1].selectedChoiceId = currentQueOption.choiceid[idx];
    
   
    // testData[questionNo - 1].selectedChoiceId = testData[
    //   questionNo - 1
    // ].choicetext.split("|^^|")[
    //   testData[questionNo - 1].choiceid
    //     .split("|^^|")
    //     .indexOf(event.target.value)
    // ];
   
   
    let temp = answerStatus;
    //temp[questionNo -1] = (testData[questionNo -1].choiceid.split('|^^|')[testData[questionNo -1].choicetext.split('|^^|').indexOf(event.target.value)] === testData[questionNo -1].correctid) ? testData[questionNo -1].qmarks : 0;
    setAnswerStatus(temp);
    // testData[questionNo - 1].answerStatus =
    //   testData[questionNo - 1].choiceid.split("|^^|")[
    //     testData[questionNo - 1].choicetext
    //       .split("|^^|")
    //       .indexOf(event.target.value)
    //   ] === testData[questionNo - 1].correctid
    //     ? true
    //     : false;
    testData[questionNo -1].answerStatus = testData[questionNo -1].selectedChoiceId === testData[questionNo -1].correctid ? true : false; 


    testData[questionNo - 1].buttonStyle = "Answered";
    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
    }
  };

  const shuffle = (array) => {
    let i = array.length,
      j = 0,
      temp;
    while (i--) {
      j = Math.floor(Math.random() * (i + 1));
      temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  React.useEffect(() => {
   
    if (questionNo === testData.length) {
      

      setDisable(true);
    }
    setAnswer(
     (testData[questionNo - 1].selectedChoiceId)
    );
    
//     setAnswer(testData[questionNo - 1].choicetext.split("|^^|")[
//       testData[questionNo - 1].choiceid
//         .split("|^^|")
//         .indexOf(testData[questionNo - 1].selectedChoiceId)
//     ]
// )
// const toTop = () => {
//   console.log('HERE');
//   // window.scrollBy({
//   //     top: 0,
//   //     behavior: "smooth"
//   //   });
//   document.body.scrollTop = 0;
//   // document.documentElement.scrollTop = 0;
// };
    if (clearFlag) {
      testData[questionNo - 1].selectedChoiceId = "";
      // toTop();
      setAnswer("");
      let temp = answerStatus;
      //temp[questionNo -1] = 0;
      setAnswerStatus(temp);
      testData[questionNo - 1].buttonStyle = "Not Answered";
      let collectData = {quesData: testData, examDetail: examDetail}
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      window.localStorage.setItem(examId, encode);
      
      setClearFlag(false);
    }
  }); //,[selectAnswer]

  const StyledRadio = (props) => {
    const classes = useStyles();
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="#fff"
        checkedIcon={
          selectAnswer !== " " && selectAnswer !== undefined ? (
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          ) : (
            <span className={classes.icon} />
          )
        }
        icon={<span className={classes.icon} />}
        {...props}
        // onClick={() => getAnswer(props.value)}
        onClick={getAnswer}
      />
    );
  };

  return (
   <>
      <div className="form-group">
        <label>
          { (questionNo === 'Undefined') ?
          <>
              <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
           <span className="question-num" >
            Q . {testData[questionNo - 1].passageQuestionNo} of {testData.length}
          </span>  
            </Typography>
              <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              // PaperProps={{
              //   style: { height: '4%',textAlign:"center"},
              // }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <h5>{testData[questionNo -1].qid}</h5>
            </Popover> 
         </>
          :
          <>
          <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
        <span className="question-num">Q.{questionNo} of {testData.length}</span>
                
        </Typography>
          {/* <Button> Question No {questionNo}</Button>< br/>  */}
          <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          // PaperProps={{
          //   style: { height: '4%',textAlign:"center"},
          // }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <h5>{testData[questionNo -1].qid}</h5>
        </Popover> 
       </> 
              }
              {examDetail[0].comprehensionflag && testData[questionNo - 1].passageheader  ?  
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].passageheader }}/> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passageheader || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_passageheader ? "" : "10px" }} /> : null}</> :
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}</>}
        </label>
      </div>
      <div className={!examDetail[0].comprehensionflag && testData[questionNo -1].passagetext  ? "row ":"row paraque-box"}>
      {examDetail[0].comprehensionflag && testData[questionNo -1].passagetext ?
        <div className="col-md-6">
          <p>
            <b>Passage</b>
          </p>
          <div className="question-para" style={{fontSize: '16px',textAlign: 'justify'}}>
          {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passagetext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].passagetext }} /> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passagetext == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passagetext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_passagetext ? "" : "10px" }} /> : null}
          </div>
          {/* Need to check here (Dhaval) */}
          {((testData[questionNo -1].passageImage !== undefined && testData[questionNo -1].passageImage !== null && testData[questionNo -1].passageImage !== "") ||
            (testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "")) ? (
            <div>
              <br />
              {supportedFileExtensions.image.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                    testData[questionNo -1].passageImageURL
                    :
                    v1_question_images_link + testData[questionNo -1].passageImage
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                      testData[questionNo -1].passageImageURL
                      :
                      v1_question_images_link + testData[questionNo -1].passageImage
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
              <br/><br />
            </div>
          ) : (
            <></>
          )}
        </div>
        : ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
            (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
            <div className="col-md-6">
              {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
            </div>
          ) : (
            <></>
          )

                 }

            
        <div className={examDetail[0].comprehensionflag ?  "col-md-6": examDetail[0].instancename === "Multi Choice Single Correct" ? "col-md-12" : "col-md-6"} style={{width: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Multiple Choice Single Correct' && testData[questionNo -1].qresourcepath === '' && '100%', paddingTop: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Multiple Choice Single Correct' && testData[questionNo -1].qresourcepath === '' && '20px'}}>
        {examDetail[0].comprehensionflag  && testData[questionNo -1].passagetext? <p><b>Question</b></p>:" "}
          <div className="form-group question-right">
          {examDetail[0].comprehensionflag && testData[questionNo -1].passagetext ? 
          <>
            
            <label className="control-label">
              
            {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
            </label> 
            {
              ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
                (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
                <div>
                  {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <img
                      src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                        testData[questionNo -1].question_resource_url.split('|^^|')[0]
                        :
                        v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                      <audio
                        controls
                        src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                          testData[questionNo -1].question_resource_url.split('|^^|')[0]
                          :
                          v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                        }
                        key={testData[questionNo -1].qid}
                        style={{ maxWidth: "100%" }}
                        alt=""
                      />
                      : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                        <video
                          controls
                          src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                            testData[questionNo -1].question_resource_url.split('|^^|')[0]
                            :
                            v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                          }
                          key={testData[questionNo -1].qid}
                          style={{ maxWidth: "100%" }}
                          alt=""
                        />
                        : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                          <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                            testData[questionNo -1].question_resource_url.split('|^^|')[0]
                            :
                            v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                          }
                            download>CLICK TO DOWNLOAD</a>
                          :
                          null
                  }
                  <br/><br />
                </div>
              ) : (
                <></>
              )
            }
            </>:<></>}
            
                        <FormControl component="fieldset"
                        style={{display:'block'}}
                        >
                            
                        <RadioGroup
                            defaultValue="options"
                            aria-label="options"
                            name="customized-radios"
                            
                        >
                          {/* {testData[questionNo -1].choicetext.split('|^^|').map((item,index) => 
                            (
                            <FormControlLabel
                            value={item}
                            key={index}
                            control={<StyledRadio />}
                            style={{ color: testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)] === item ? '#fff' : '#525252'}}
                            label={<><span className="mr-3" dangerouslySetInnerHTML={{__html: item}} style={{color:testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)] === item  ? "#fff" : "#525252" }} />
                            {testData[questionNo -1].chresourcepath !== "" ? <img style={{marginLeft:"15px"}} src={`${WEB_URL}ah-resources/questionimages/`+testData[questionNo -1].chresourcepath.split('|^^|')[index]}/>:""}</>
                          }
                            checked={testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)] === item  ? true : false}
                            className={testData[questionNo -1].choicetext.split('|^^|')[testData[questionNo -1].choiceid.split('|^^|').indexOf(testData[questionNo -1].selectedChoiceId)] === item  ? "radio checked" : "radio"}
                        
                            />
                          ))
                        
                        } */}
                {currentQueOption.choiceid.map((item, index) =>
                 (
                   <>
                  <FormControlLabel
                    value={item}
                    key={index}
                    control={<StyledRadio />}
                    style={{ color: testData[questionNo - 1].selectedChoiceId === item ? '#fff' : '#525252' }}
                    label={<>
                            {!((testData[questionNo -1].chresourcepath.split('|^^|')[index] !== undefined && testData[questionNo -1].chresourcepath.split('|^^|')[index] !== null && testData[questionNo -1].chresourcepath.split('|^^|')[index] !== "") ||
                              (testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "")) ? (
                                <span>
                                  {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && currentQueOption.translated_label[index] == "--null--") ? <span className="mr-3" dangerouslySetInnerHTML={{ __html: currentQueOption.label[index] }} style={{ color: testData[questionNo - 1].selectedChoiceId === item ? "#fff" : "#525252" }} /> : null}
                                  {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || currentQueOption.translated_label[index] == "--null--") ? <><br /><br /></> : null}
                                  {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span className="mr-3" dangerouslySetInnerHTML={{ __html: currentQueOption.translated_label[index] != "--null--" ? currentQueOption.translated_label[index] : translationMissingMessage }} style={{ color: testData[questionNo - 1].selectedChoiceId === item ? "#fff" : "#525252", fontSize: testData[questionNo -1].translated_choicetext.split('|^^|')[index] != "--null--" ? "" : "10px" }} /> : null}
                                </span>  
                              ) : (
                              <div>
                                <br />
                                {supportedFileExtensions.image.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                                  <img
                                    src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                                      testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                                      :
                                      v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                                    }
                                    key={testData[questionNo -1].qid}
                                    style={{ maxWidth: "100%" }}
                                    alt=""
                                  />
                                  : supportedFileExtensions.audio.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                                    <audio
                                      controls
                                      src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                                        testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                                        :
                                        v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                                      }
                                      key={testData[questionNo -1].qid}
                                      style={{ maxWidth: "100%" }}
                                      alt=""
                                    />
                                    : supportedFileExtensions.video.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                                      <video
                                        controls
                                        src={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                                          testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                                          :
                                          v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                                        }
                                        key={testData[questionNo -1].qid}
                                        style={{ maxWidth: "100%" }}
                                        alt=""
                                      />
                                      : supportedFileExtensions.file.includes(testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.')[testData[questionNo -1].chresourcepath.split('|^^|')[index].split('.').length - 1].toLowerCase()) ?
                                        <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== undefined && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== null && testData[questionNo -1].choice_resource_url.split('|^^|')[index] !== "") ?
                                          testData[questionNo -1].choice_resource_url.split('|^^|')[index]
                                          :
                                          v1_question_images_link + testData[questionNo -1].chresourcepath.split('|^^|')[index]
                                        }
                                          download>CLICK TO DOWNLOAD</a>
                                        :
                                        null
                                }
                              </div>
                            )}</>
                    }
                    checked={testData[questionNo - 1].selectedChoiceId === item ? true : false}
                    className={testData[questionNo - 1].selectedChoiceId === item ? "radio checked" : "radio"}       
                  />
                  </>
                  )
                  )

                }
                     
                      </RadioGroup>
                      </FormControl>
            </div>
        </div>
        </div>
    </>
  );
};

