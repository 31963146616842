import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import cache from "memory-cache";
import RecordRTC from "recordrtc";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Paper,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
  Popover,
} from "@material-ui/core";
import SpeechToText from "speech-to-text";
import Sentiment from "sentiment";
import emoji from "node-emoji";
import axios from "../../../utils/axiosConfig";
import Cookies from "js-cookie";
import { WEB_URL } from "../../../utils/constants";
import onWindowReload from "../../../utils/reloadFunction";
import { v1_question_images_link } from "./v1-links";
import { useSelector } from 'react-redux'
import { getOrgId } from "../../../reducer/dashboardSlice";
import { serverConstants, localhostConstants } from "../../../utils/serverConstants";

// Define styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "0px 5px",
  },
  countdownSnackbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Export a React functional component
export default (props) => {
  const classes = useStyles();
  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    marksScored,
    setMarksScored,
    totalMarks,
    setTotalMarks,
    wrongAnswerCount,
    setWrongAnswerCount,
    disable,
    setDisable,
    setTestData,
    windowSwitchCount,
    setWindowSwitch,
    isRecording,
    setIsRecording,
    isVideoSent,
    setIsVideoSent,
  } = props;

  // Initialize component state
  const [answer, setAnswer] = React.useState(testData[questionNo - 1].descriptiveText);
  const [error, setError] = React.useState(false);
  const [recordVideo, setRecordVideo] = React.useState(null);
  const [adminToken, setAdminToken] = React.useState("");
  const [interimText, setInterimText] = React.useState("");
  const [finalisedText, setFinalisedText] = React.useState([]);
  const [listening, setListening] = React.useState(false);
  const [language, setLanguage] = React.useState(examDetail[0].speechlang + "-IN");
  const [emojiValue, setEmojiValue] = React.useState(0);
  const [listener, setListener] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [filename, setFilename] = React.useState("");
  const [blobUrl, setBlobUrl] = React.useState(`${WEB_URL}ah-resources/user-files/autoSpeech/` + filename);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [countdown, setCountdown] = React.useState(3);
  const [disabled, setDisabled] = React.useState(testData[questionNo - 1].descriptiveText !== null);
  const [startButtonDisabled, setStartButtonDisabled] = React.useState(false);
  const [isRecordingByQuestion, setIsRecordingByQuestion] = React.useState({});
  const [uploadingStatus, setUploadingStatus] = React.useState({});
  const [audioUploaded, setAudioUploaded] = React.useState(false);
  let [audioButtonCount, setAudioButtonCount] = React.useState(0);
  const [retryClicked, setRetryClicked] = React.useState(false);
  const [audioPlaying, setAudioPlaying] = React.useState(false);

  // Handle mouse hover for question number popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Function to handle the window reload event
  onWindowReload(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData);

  const open = Boolean(anchorEl);

  const onAnswerText = (text) => {
    setClearFlag(false);
    setCheckFlag(true);

    let temp = answer;

    // Update the state of quesData using the setTestData function
    setTestData((prevTestData) => {
      const updatedTestData = [...prevTestData];
      const currentQuestion = updatedTestData[questionNo - 1];

      currentQuestion.answerStatus = text === currentQuestion.choicetext;

      if (text !== "") {
        currentQuestion.buttonStyle = "Answered";
        currentQuestion.descriptiveText = text;
        setError(false);
      } else {
        currentQuestion.buttonStyle = "Not Answered";
        if (!examDetail[0].answermandatoryflag) {
          setError(true);
        }
      }

      return updatedTestData;
    });

    setAnswerStatus(temp);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  // Function to start recording audio
  const startRecord = async () => {
    // Check if audio is already recorded, and if so, disable the button
    if (testData[questionNo - 1].descriptiveText !== null) {
      setStartButtonDisabled(true);
      return;
    }
    // Enable the start button if the user clicked "Retry"
    if (retryClicked) {
      setStartButtonDisabled(false);
      setRetryClicked(false);
      return;
    }

    // // Disable the start button during the countdown
    // setStartButtonDisabled(true);

    // // Display countdown to the user using a Snackbar
    // for (let i = 3; i >= 1; i--) {
    //   setCountdown(i);
    //   // Display the Snackbar with the countdown
    //   setSnackbarOpen(true);
    //   await new Promise((resolve) => setTimeout(resolve, 1000));
    //   // Close the Snackbar after the timeout
    //   setSnackbarOpen(false);
    // }

    // Set recording status for the current question to true
    setIsRecordingByQuestion((prev) => ({ ...prev, [questionNo]: true }));

    // Use getUserMedia to access the user's microphone
    getUserMedia(async (stream) => {
      let recordVideo = RecordRTC(stream, { type: "audio" });
      setRecordVideo(recordVideo);

      await recordVideo.startRecording();
      // startListening();
      axios({
        method: "POST",
        url: `${WEB_URL}admin/ws/integration/authenticate`,
        headers: {
          "X-Username": "admin@demo.com",
          "X-Password": "admin@2020",
        },
      })
        .then((response) => {
          setAdminToken(response.data.data.tokenId);
        })
        .catch((error) => {
          // Handle error
        });
    });

    setIsRecording(true);
    setIsVideoSent(true);
    setUploadingStatus((prev) => ({ ...prev, [questionNo]: { uploading: false, success: false } }));
  };

  const stopRecord = async () => {
    if (recordVideo) {
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: true, success: false },
      }));

      try {
        await new Promise((resolve, reject) => {
          recordVideo.stopRecording(async () => {
            setIsRecording(false);
            setCountdown(3);
            setStartButtonDisabled(true);

            const filename = `${firstName}_${lastName}_${props.examId}_${testData[questionNo - 1].qid}.wav`;
            const audioData = new Blob([recordVideo.getBlob()], {
              type: "audio/webm;codecs=opus",
            });

            var formData = new FormData();
            formData.append("file", audioData, filename);

            axios
              .post("/result/uploadaudio", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                const file_url = response.data.file_url;
                const file_name = response.data.file_name;

                testData[questionNo - 1].answerResourcePath = file_url;
                testData[questionNo - 1].answerResourceName = file_name;
                testData[questionNo - 1].descriptiveText = "Not Required";
                testData[questionNo - 1].buttonStyle = "Answered";

                // console.log("Audio file uploaded successfully:", file_url);
                setAudioUploaded(true);
                setIsVideoSent(false);
                setCheckFlag(true);
                setUploadingStatus((prev) => ({
                  ...prev,
                  [questionNo]: { uploading: false, success: true },
                }));
                resolve();
              })
              .catch((error) => {
                console.error("Error uploading audio file on azure:", error);
                const errorMessage = (error.response && error.response.data && error.response.data.message) || "Unknown error occurred";
                setUploadingStatus((prev) => ({
                  ...prev,
                  [questionNo]: { uploading: false, success: false },
                }));
                setIsVideoSent(false);

                // Option to retry
                const shouldRetry = window.confirm("Audio upload failed. Do you want to retry?");
                if (shouldRetry) {
                  setStartButtonDisabled(false);
                  setIsRecordingByQuestion((prev) => ({ ...prev, [questionNo]: false }));
                } else {
                  reject(errorMessage); // Reject the promise with the original error message
                }
              });
          });
        });
      } catch (error) {
        console.error("Error uploading audio file on azure:", error);
        const errorMessage = error.message || "Unknown error occurred";
        setUploadingStatus((prev) => ({
          ...prev,
          [questionNo]: { uploading: false, success: false },
        }));
        setIsVideoSent(false);

        // Option to retry
        const shouldRetry = window.confirm("Audio upload failed. Do you want to retry?");
        if (shouldRetry) {
          // Set the retryClicked state to true
          setRetryClicked(true);
          // You can add additional logic here if needed
        } else {
          // Reject the promise with the original error message
          return Promise.reject(errorMessage);
        }
      }
    } else {
      setIsVideoSent(false);
    }
  };

  // Function to access the user's microphone
  function getUserMedia(callback) {
    navigator.getUserMedia({ audio: true, video: false }, callback, (error) => alert(JSON.stringify(error)));
  }

  const handleAudioClick = async () => {
    handleAudioPlay();
    if (audioButtonCount < 4) {
      setAudioButtonCount(audioButtonCount + 1);
      testData[questionNo - 1].audioButtonCount = audioButtonCount + 1;
      // console.log("audioButtonCount", audioButtonCount + 1);
    }

    if (audioButtonCount === 4) {
      console.log("chances exhausted");
    }
    // console.log("audioPlaying --> ", audioPlaying);
  };

  // Function to handle audio playback start
const handleAudioPlay = () => {
  if(audioButtonCount < 3){
  setAudioPlaying(true);
  }else{
    setAudioPlaying(false);
  }
};

const handleAudioPause = () => {
  setAudioPlaying(false);
};

// Function to handle audio playback end
const handleAudioEnd = () => {
  setAudioPlaying(false);
};

  // // Function to handle interim speech recognition text
  // const onAnythingSaid = (text) => {
  //   setInterimText(text);
  // };

  // // Function called when speech recognition ends
  // const onEndEvent = () => {
  //   if (listening) {
  //     startListening();
  //   } else {
  //     setListening(false);
  //   }
  // };

  // // Function to handle final speech recognition results
  // const onFinalised = (text) => {
  //   setFinalisedText([text, ...finalisedText]);
  //   setAnswer(answer + " " + text);
  //   setInterimText("");

  //   const sentiment = new Sentiment();
  //   const result = sentiment.analyze(text);
  //   setEmojiValue(result.score);
  //   onAnswerText(text);
  // };

  // // Function to start speech recognition
  // const startListening = () => {
  //   try {
  //     let listener = new SpeechToText(onFinalised, onEndEvent, onAnythingSaid, language);
  //     setListener(listener);

  //     listener.startListening();
  //     setListening(true);
  //   } catch (err) {
  //     // Handle error
  //   }
  // };

  // // Function to stop speech recognition
  // const stopListening = () => {
  //   listener.stopListening();
  //   setListening(false);
  // };
  React.useEffect(() => {
    setAudioButtonCount(testData[questionNo - 1].audioButtonCount || 0);
    setAudioPlaying(false);
  }, [questionNo - 1]);

  React.useEffect(() => {
    // Enable the start button after the recording stops
    if (!isRecording) {
      setStartButtonDisabled(false);
    }
  }, [isRecording]);

  const orgId = useSelector(getOrgId)

  React.useEffect(() => {
    // Disable the component when it's the last question
    if (questionNo === testData.length) {
      setDisable(true);
    }
    if (orgId != undefined && orgId != "") {
    try {
      axios
        .get("/dashboard/getProfile", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            orgId: orgId,
          },
        })
        .then((profData) => {
          let userData = profData.data.profileData;
          setFirstName(userData.firstname);
          setLastName(userData.lastname);
          setFilename(`${firstName}_${lastName}_${props.examId}_${testData[questionNo - 1].qid}.wav`);
        })
        .catch((error) => {
          // Handle error
        });
    } catch (error) {
      // Handle error
    }
  }
    setAnswer(testData[questionNo - 1].descriptiveText);
    //testData[questionNo - 1].answerResourcePath = filename;
    setBlobUrl(`${WEB_URL}ah-resources/user-files/autoSpeech/` + filename);

    if (clearFlag) {
      testData[questionNo - 1].descriptiveText = "Answered";
      let temp = answer;
      setAnswerStatus(temp);
      setAnswer("");
      testData[questionNo - 1].buttonStyle = "Answered";
      let collectData = { quesData: testData, examDetail: examDetail };
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
      window.localStorage.setItem(examId, encode);
      setClearFlag(false);
    }
  }, [clearFlag, answer, blobUrl, finalisedText, filename, questionNo]);

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="question-num">
          Q.{questionNo} of {testData.length}
        </span>
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h5>{testData[questionNo - 1].qid}</h5>
      </Popover>
      <span style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} />
      <br />
      {testData[questionNo - 1].qresourcepath !== "" && testData[questionNo - 1].audioButtonCount !== 4 ? (
  <>
    <audio
      style={{ marginTop: "10px" }}
      src={
        testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== undefined &&
        testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== null &&
        testData[questionNo - 1].question_resource_url.split("|^^|")[0] !== ""
          ? testData[questionNo - 1].question_resource_url.split("|^^|")[0]
          : v1_question_images_link + testData[questionNo - 1].qresourcepath.split("|^^|")[0]
      }
      controls={!isRecording}
      onPlay={handleAudioClick}
      onPause={handleAudioPause}
      onEnded={handleAudioEnd}
    />
  </>
) : (
  <>
    {testData[questionNo - 1].audioButtonCount === 4 && (
      <>
        <br />
        <br />
        <span style={{ color: "red", fontSize: "14px", fontWeight: "bold", padding: "8px", borderRadius: "5px" }}>
          Limit reached: Play audio up to 3 times only.
        </span>
      </>
    )}
  </>
)}


      {testData[questionNo - 1].answerResourcePath && testData[questionNo - 1].answerResourcePath !== "" ? (
        <>
          <br />
          <br />
          <p style={{ marginBottom: "4px" }}>
            {testData[questionNo - 1].answerResourceName != null ? <b>Recorded Audio</b> : "Please record the audio"}
          </p>
          <audio
            style={{ marginBottom: "10px" }}
            // src={`https://v2testingstorage.blob.core.windows.net/user-speech-evaluation/` + testData[questionNo - 1].answerResourceName}
            src={`${serverConstants.find(item => window.location.href.includes(item.urlUniqueString))
              ? serverConstants.find(item => window.location.href.includes(item.urlUniqueString)).azureSpeechStorageURL
              : localhostConstants.azureSpeechStorageURL}${testData[questionNo - 1].answerResourceName}`}
            controls="controls"
          />
        </>
      ) : (
        <></>
      )}

      <br />
      {!isRecording && !testData[questionNo - 1].answerResourcePath && !testData[questionNo - 1].disabled && !retryClicked && (
        <>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>Click below button to start recording</p>
          <Button
  variant="outlined"
  onClick={startRecord}
  disabled={isRecordingByQuestion[questionNo] || testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled || audioPlaying}
  style={{
    marginBottom: "10px",
    color: "white",
    borderColor: isRecordingByQuestion[questionNo] || testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled || audioPlaying ? "#CCCCCC" : "red",
    backgroundColor: isRecordingByQuestion[questionNo] || testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled || audioPlaying ? "#E0E0E0" : "red",
  }}
>
<b>Start Recording</b>
</Button>

        </>
      )}
      {isRecording && !testData[questionNo - 1].answerResourcePath && !testData[questionNo - 1].disabled && (
        <>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>Click below button to stop recording</p>
          <Button
  variant="outlined"
  onClick={stopRecord}
  disabled={testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled}
  style={{
    borderColor: testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled ? "#CCCCCC" : "#CC0000",
    color: testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled ? "#CCCCCC" : "white",
    backgroundColor: testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled ? "#E0E0E0" : "#CC0000",
    marginBottom: "10px",
  }}
>
  <b>Stop Recording</b>
</Button>

        </>
      )}

      {uploadingStatus[questionNo] && uploadingStatus[questionNo].uploading && !uploadingStatus[questionNo].success ? (
        <p style={{ color: "#333", marginBottom: "8px" }}>Audio is uploading...</p>
      ) : testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled ? (
        <>
          <p style={{ marginTop: "8px", marginBottom: "8px", color: "#4CAF50", fontSize: "16px", fontWeight: "bold" }}>Audio saved successfully !</p>
        </>
      ) : (
        <></>
      )}

      {/* Display audio player for all previous questions
{Array.from({ length: questionNo}).map((_, index) => (
  <div key={index}>
    {testData[index].answerResourcePath && (
      <>
       <audio controls>
        <source src={testData[index].answerResourcePath} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      </>
    )}
  </div>
))} */}

      <div style={{ display: "none", marginTop: "10px" }}>
        <TextField id="outlined-search" label="Enter your Answer" type="search" variant="outlined" value={filename} onChange={onAnswerText} />
      </div>
      {/* </Paper> */}
      {/* <Snackbar open={snackbarOpen} autoHideDuration={1000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="info"
          className={classes.countdownSnackbar} // Apply the new class here
        >
          Recording starts in {countdown}...
        </Alert>
      </Snackbar> */}

      <div>
        <div style={{ display: "none" }}>
          <Grid container>
            <AppBar position="static">
              <Toolbar variant="">
                <Typography variant="h6" color="inherit">
                  What language are you going to speak in?
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid container spacing={16}>
              <Grid item xs={12} md={7}>
                <Paper>
                  <Grid container spacing={16}>
                    <Grid item xs={12} lg={6}>
                      <FormControl>
                        <InputLabel>Language</InputLabel>
                        <MenuItem key={examDetail[0].speechlang + "-IN"} value={examDetail[0].speechlang + "-IN"}>
                          {examDetail[0].speechlang + "-IN"}
                        </MenuItem>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ paddingLeft: "40px", paddingTop: "10px" }}>
                      {listening ? (
                        <Button color="primary" onClick={() => this.stopListening()} variant="contained">
                          Stop
                        </Button>
                      ) : (
                        <Button color="primary" onClick={() => this.startListening()} variant="contained">
                          Start
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={5}>
                <Paper>
                  Status: <span> {listening ? "Listening..." : "Finished"} </span> <br />
                  <Typography variant="overline" gutterBottom>
                    Current utterances
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {interimText}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <p>
                  <strong>
                    Sentiment analysis Score:{" "}
                    {emojiValue > 0
                      ? emojiValue < 5
                        ? `${emoji.get("slightly_smiling_face")}`
                        : emojiValue < 10
                        ? `${emoji.get("yum")}`
                        : emojiValue < 20
                        ? `${emoji.get("wink")}`
                        : emojiValue < 30
                        ? `${emoji.get("smile")}`
                        : `${emoji.get("joy")}`
                      : emojiValue < -2
                      ? `${emoji.get("confused")}`
                      : emojiValue < -5
                      ? `${emoji.get("pensive")}`
                      : emojiValue < -8
                      ? `${emoji.get("disappointed")}`
                      : emojiValue < -11
                      ? `${emoji.get("angry")}`
                      : emojiValue < -14
                      ? `${emoji.get("rage")}`
                      : `${emoji.get("neutral_face")}`}{" "}
                    {emojiValue}
                  </strong>
                </p>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Translated Text</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ height: "150px", overflow: "scroll", display: "block" }}>
                      {finalisedText.map((str, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {str}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
