import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import onWindowReload from "../../utils/reloadFunction";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import modalHandler from "../../utils/modalHandler";
import axios from "../../utils/axiosConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "425px",
  },
}));

export default function LanguageModal(Props) {
  const classes = useStyles();
  let reload = true;
  const { showLanguageModal, setShowLanguageModal, modalHandlerData } = Props
  const {
    row, index,name,userExamId,props,setLoading,setModelShow,setMsgModal,setResumeLoader,setMessageModal,setMessage,setCodeLanguages
  } = modalHandlerData;
  const [languages, setLanguages] = useState([])
  const [languageCode, setLanguageCode] = useState()
  const [showLanguageRequiredMessage, setShowLanguageRequiredMessage] = useState(false)
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

  window.onbeforeunload = function () {
    return null; // return null to avoid pop up
  };

  useEffect(()=>{
    try {
      axios.get('/dashboard/getLanguages',{  
          headers: {
            'Access-Control-Allow-Origin': '*',
            userexamid: userExamId,
          }
      }).then(result =>{
          // console.log(result.data)
          setLanguages(result.data.languagesData)
          if (result.data.languagesData && result.data.languagesData.length > 0 && result.data.preferredLanguage && row.languageLocale) {
            if (row.languageLocale.split(",").includes(result.data.preferredLanguage) && result.data.languagesData.find(item => item.locale == result.data.preferredLanguage) != undefined) {
              setLanguageCode(result.data.preferredLanguage)
            }
          }
      }).catch(error => {
        console.log(error) 
      })
    } catch (error) {
      console.log(error)
    }   
  }, [])

  return (
    <div>
      <Modal
        disableEnforceFocus={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showLanguageModal}
        //onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showLanguageModal}>
          <div className={classes.paper} style={{ textAlign: "justify" }}>
            <div style={{ textAlign: "center" }}>
              <h3 id="transition-modal-title" style={{ color: "#6f2981", marginBottom: "30px" }}>
                Select Test Language
              </h3>
            </div>
            <div>
                <div className="form-group">
                    <FormControl style={{ width: "-webkit-fill-available" }}>
                        <InputLabel id="demo-simple-select-helper-label">Test Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="language"
                            onChange={(e) => { setShowLanguageRequiredMessage(false); setLanguageCode(e.target.value) }}
                            name='lang-select'
                            value={languageCode || ""}
                        >
                        {languages && Array.isArray(languages) && languages.length != 0 && 
                          languages.filter(item => row.languageLocale.split(",").includes(item.locale)).map((item, index) => {
                            return <MenuItem key={index} value={item.locale}>{item.language}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                    {showLanguageRequiredMessage ?
                      <Typography style={{ color: "red", marginTop: "10px", paddingBottom: 0 }}>Test Language Required !!</Typography>
                      : 
                      <></>
                    }
                    {showConfirmationMessage ?
                      <Typography style={{ marginTop: "20px", paddingBottom: 0, textAlign: "center" }}>Test Language cannot be changed after the test has started. Are you sure you want to continue with this language?</Typography>
                      : 
                      <></>
                    }
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      <a className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={() => setShowLanguageModal(false)}>
                        <strong>GO BACK</strong>
                      </a>
                      <a className="btn btn-blue" style={{ borderRadius: "10px", marginLeft: "15px" }} onClick={languageCode ? showConfirmationMessage ? () => { setShowLanguageModal(false); modalHandler(row, index,name,props,setLoading,setModelShow,setMsgModal,setResumeLoader,setMessageModal,setMessage,setCodeLanguages,languageCode); } : () => setShowConfirmationMessage(true) : () => { setShowLanguageRequiredMessage(true) }}>
                        <strong>PROCEED</strong>
                      </a>
                      
                    </div>
                </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}