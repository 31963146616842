import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

// import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
// import clsx from "clsx";
import cache from 'memory-cache';
import {Popover,Typography} from '@material-ui/core';
import { WEB_URL } from '../../../utils/constants';
import onWindowReload from '../../../utils/reloadFunction';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from './v1-links';
import { translationMissingMessage } from './translationConstants';
const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding:'0px 5px',
    },
}));

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const {testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail
      , answerStatus, setAnswerStatus, marksScored, setMarksScored, totalMarks, setTotalMarks,
      wrongAnswerCount, setWrongAnswerCount,disable, setDisable,setTestData,windowSwitchCount,setWindowSwitch,setWebProcteringZero} = props;
      const [tempJson, setTempJson] = React.useState({});
      
const [answer, setAnswer] = React.useState('');//testData[questionNo -1].selectedChoiceId?testData[questionNo -1].selectedChoiceId:''
const [error, setError] = React.useState(false);
const [ans, setAns] = React.useState('');
const [anchorEl, setAnchorEl] = React.useState(null);

const handlePopoverOpen = (event) => {
setAnchorEl(event.currentTarget);
};

const handlePopoverClose = () => {
setAnchorEl(null);
};

onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)


const open = Boolean(anchorEl);

const onAnswerText = (index, e) => {

  setClearFlag(false);
  setCheckFlag(true);
 
  // testData[questionNo -1].descriptiveText = e.target.value;

  let newStr = answer.split('|^^|'); // or newStr = [...str];
      newStr[index] = e.target.value;
//  let newVal = answer.split('|^^|')[index].replace('',e.target.value) 
   setAnswer(newStr.join('|^^|'))


  let temp = answer;
  //temp[questionNo -1] = (e.target.value === testData[questionNo -1].choicetext) ? testData[questionNo -1].qmarks : 0;
  setAnswerStatus(temp);
  testData[questionNo -1].answerStatus = (testData[questionNo -1].descriptiveText !== '') ? true : false;
  
  // if(testData[questionNo -1].descriptiveText !=='null' || testData[questionNo -1].descriptiveText !== null || testData[questionNo -1].descriptiveText !== ""){
  //     testData[questionNo -1].buttonStyle = 'Answered';
  //     setError(false);
  // }else{
  //     testData[questionNo -1].buttonStyle = 'Not Answered';
  //     if(!examDetail[0].answermandatoryflag){
  //         setError(true);
  //     }
  // }
  testData[questionNo -1].descriptiveText = newStr.join('|^^|');
  // if(testData[questionNo -1].descriptiveText === testData[questionNo -1].choicetext){
  //   testData[questionNo -1].selectedChoiceId = testData[questionNo -1].correctid
  // }else{
  //   testData[questionNo -1].selectedChoiceId = null
  // }
  let collectData = {quesData: testData, examDetail: examDetail}
  let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
  window.localStorage.setItem(examId, encode);
  
}
        
    // const onAnswerText = (e) => {
    //     setClearFlag(false);
    //     setCheckFlag(false);
        
    //     testData[questionNo -1].descriptiveText = e.target.value;
    //     setAnswer(e.target.value);
    //     let temp = answer;
    //     //temp[questionNo -1] = (e.target.value === testData[questionNo -1].choicetext) ? testData[questionNo -1].qmarks : 0;
    //     setAnswerStatus(temp);
    //     testData[questionNo -1].answerStatus = (e.target.value == testData[questionNo -1].choicetext) ? true : false;
    //     
    //     
    //     if(answer){
    //         testData[questionNo -1].buttonStyle = 'Answered';
    //         setError(false);
    //     }else{
    //         testData[questionNo -1].buttonStyle = 'Not Answered';
    //         if(!examDetail[0].answermandatoryflag){
    //             setError(true);
    //         }
    //     }
        
    //     setAnswer(e.target.value);
    // }
   
    React.useEffect(() => {
      
      if(questionNo === testData.length ){
        

        setDisable(true);
      }
      setAnswer(testData[questionNo -1].descriptiveText !=="null" && testData[questionNo -1].descriptiveText !== null && testData[questionNo -1].descriptiveText !== "" ?testData[questionNo -1].descriptiveText:'');
      // setAnswer(testData[questionNo -1].selectedChoiceId)
     // setAns("");
     // setAnswer('');
     // setTempJson({});
      let tempOption = [];
      let tempJson1 = {};
       testData[questionNo - 1].chkey.split('|^^|').map((item, index) => {
          if(tempOption.indexOf(item) === -1){
            tempOption.push(item);
            tempJson1[item] = [];
          }
          tempJson1[item].push(testData[questionNo - 1].choiceid.split('|^^|')[index]+'|'+
          testData[questionNo - 1].choicetext.split('|^^|')[index]);
       })
  
      let ans = '';
      if(Object.keys(tempJson1).length > 1){
      for(let i = 1; i <Object.keys(tempJson).length ; i++){
          ans+= ' |^^|';
          } 
          setAns(ans);
      }
  
    //  if(testData[questionNo -1].selectedChoiceId!==null ||testData[questionNo -1].selectedChoiceId!=="" ){
    //   setTempJson({});

    //  }else{
      setTempJson(tempJson1);

    //  }
     
      if(clearFlag){
      testData[questionNo -1].descriptiveText = "";
        let temp = answer;
        //temp[questionNo -1] = 0;
        setAnswerStatus(temp);
        
        setAnswer("");
       // setAns("");
       // setTempJson({});
        testData[questionNo -1].buttonStyle = 'Not Answered';
        // if(testData[questionNo -1].descriptiveText === testData[questionNo -1].choicetext){
        //   testData[questionNo -1].selectedChoiceId = testData[questionNo -1].correctid
        // }else{
        //   testData[questionNo -1].selectedChoiceId = null
        // }
        let collectData = {quesData: testData, examDetail: examDetail}
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);
        
        setClearFlag(false);
      }
      let collectData = {quesData: testData, examDetail: examDetail}
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      window.localStorage.setItem(examId, encode);   
    },[questionNo,clearFlag])

    return (
        <>
   {/* <strong> Question No {questionNo}</strong>< br/>  */}
   <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
      <span className="question-num">Q.{questionNo} of {testData.length}</span>
      </Typography>
        {/* <Button> Question No {questionNo}</Button>< br/>  */}
        <Popover
        id="mouse-over-popover"
        className={classes.popover}
         classes={{
          paper: classes.paper,
        }}
       // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
         anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
         <h5>{testData[questionNo -1].qid}</h5>
      </Popover>

      {examDetail[0].comprehensionflag && testData[questionNo - 1].passageheader ?
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].passageheader }}/> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passageheader || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_passageheader ? "" : "10px" }} /> : null}</> :
          <>{(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
          {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}</>}

      <div className={!examDetail[0].comprehensionflag && testData[questionNo -1].passagetext  ? "row ":"row paraque-box"}>
        {examDetail[0].comprehensionflag && testData[questionNo - 1].passagetext ?
          <div className="col-md-6">
            <p>
              <b>Passage</b>
            </p>
            <div className="question-para" style={{ fontSize: '16px', textAlign: 'justify' }}>
              {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_passagetext == null) ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].passagetext}} /> : null}
              {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passagetext == null) ? <><br /><br /></> : null}
              {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].translated_passagetext || translationMissingMessage}} style={{ fontSize: testData[questionNo - 1].translated_passagetext ? "" : "10px" }} /> : null}
            </div>
            {((testData[questionNo -1].passageImage !== undefined && testData[questionNo -1].passageImage !== null && testData[questionNo -1].passageImage !== "") ||
            (testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "")) ? (
            <div>
              <br />
              {supportedFileExtensions.image.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                    testData[questionNo -1].passageImageURL
                    :
                    v1_question_images_link + testData[questionNo -1].passageImage
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                      testData[questionNo -1].passageImageURL
                      :
                      v1_question_images_link + testData[questionNo -1].passageImage
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
              <br/><br />
            </div>
          ) : (
            <></>
          )}
          </div>
          // : testData[questionNo - 1].qresourcepath !== '' ?
          //   <div className="col-md-6">
          //     <>
          //       <img className="img-responsive" src={`${WEB_URL}ah-resources/questionimages/` + testData[questionNo - 1].qresourcepath.split('|^^|')[0]} />
          //     </>
          //   </div>
            :
             <></>

        }
        <div className={examDetail[0].comprehensionflag ?  "col-md-6": examDetail[0].instancename === "Fill in the Blank" ? "col-md-12" : "col-md-6"} style={{width: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Fill In the Blanks' && testData[questionNo -1].qresourcepath === '' && '100%', paddingTop: !examDetail[0].comprehensionflag && testData[questionNo -1].questiontypename === 'Fill In the Blanks' && testData[questionNo -1].qresourcepath === '' && '20px'}}>
        {examDetail[0].comprehensionflag  && testData[questionNo -1].passagetext? <p><b>Question</b></p>:" "}
        <div className="form-group question-right">
        {examDetail[0].comprehensionflag && testData[questionNo - 1].passagetext ?
          <>
            <label className="control-label">

            {(examDetail[0].languageCode == null || examDetail[0].languageCode == "en" || examDetail[0].include_english == true) || (examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
            {examDetail[0].languageCode != null && examDetail[0].languageCode != "en" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
            </label>

          </> : <></>}

                  {/* <span style={{fontSize:"16px"}}  dangerouslySetInnerHTML={{__html: testData[questionNo -1].qtext}} /> */}
            {
                        ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
                          (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
                          <div>
                            {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                              <img
                                src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                                  testData[questionNo -1].question_resource_url.split('|^^|')[0]
                                  :
                                  v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                                }
                                key={testData[questionNo -1].qid}
                                style={{ maxWidth: "100%" }}
                                alt=""
                              />
                              : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                                <audio
                                  controls
                                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                                    :
                                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                                  }
                                  key={testData[questionNo -1].qid}
                                  style={{ maxWidth: "100%" }}
                                  alt=""
                                />
                                : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                                  <video
                                    controls
                                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                                      :
                                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                                    }
                                    key={testData[questionNo -1].qid}
                                    style={{ maxWidth: "100%" }}
                                    alt=""
                                  />
                                  : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                                      :
                                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                                    }
                                      download>CLICK TO DOWNLOAD</a>
                                    :
                                    null
                            }
                          </div>
                        ) : (
                          <></>
                        )
                      }
            <br />
            <br />
            {/* {error
            ?
            <TextField id="outlined-search" 
            error
            label="Enter your Answer" 
            type="search" 
            variant="outlined"
            onChange={onAnswerText}
           // defaultValue={answer}
            helperText="Kindly enter your answer" />
            : */}
             {testData[questionNo -1].choicetext.split('|^^|').map((item ,index) => 
                            (
                                <>
            <TextField id="outlined-search" 
            
            type="search" 
            variant="outlined"
            value={answer.split('|^^|')[index] || ''} 
            onChange={(e) => onAnswerText(index, e)} />
            <br />
            <br />
            </>
              ))}
          </div>
       </div>
       </div>
        </>
        
    )
}