import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Timer from '../Timer';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    title: {
      flexGrow: 1,
    }
}));


export default (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const {examDetail, setTimeoutModelShow} = props;

    return (
        <AppBar position="static" color="primary">
            <Toolbar variant="">
            
            <Typography variant="h6" className={classes.title}>
                
            </Typography>
            <Typography ><strong><Timer examDetail={examDetail} setTimeoutModelShow={setTimeoutModelShow} /></strong></Typography>
            </Toolbar>
        </AppBar>
    );
}